import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import foodExchange from './Food_Exchange_List';
import color_codes from './Color_Code_List';
import { motion, useAnimationControls } from "motion/react"
import { AutoComplete } from 'primereact/autocomplete';
import './form_nutritionintake.css';

const AutoCompletInput = ({original_items, value, handle_change, meal_index}) => {
    const [ is_focus, setIs_focus ] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null); // State for selected item
    const [filteredItems, setFilteredItems] = useState([]); // State for filtered items
  
    const color_code_white = '#ffffff';
    const color_code_black = '#000000';
    const [ light_text, setLight_text ] = useState(color_code_black);
    const [ light_bg, setLight_bg ] = useState(color_code_white);
  
    useEffect(() => {
      setLight_text(meal_index === 0 ? color_code_black : color_codes[meal_index]?.light_text);
      setLight_bg(meal_index === 0 ? color_code_white : color_codes[meal_index]?.light_bg);
    }, [meal_index]);
  
    useEffect(() => {
      handle_change(selectedItem);
    }, [selectedItem]);
  
    const searchItems = (event) => {
      const query = event.query.toLowerCase();
      const filtered = original_items.filter(item => item.toLowerCase().includes(query));
      setFilteredItems(filtered);
    };
  
    const itemTemplate = (item) => {
      return <div className='bg-white px-2 py-2 hover:bg-gray-200 shadow'>{item}</div>;
    };
  
    return (
        <AutoComplete
        value={value} 
        suggestions={filteredItems} 
        completeMethod={searchItems}
        placeholder={'โปรดระบุส่วนประกอบ'}
        className={'input-autocomplete rounded-md w-full h-10 border ' + ((is_focus) ? ((meal_index === 0) ? 'border-orange-primary outline-orange-primary border-2 ' : ' ') : ' ') + ((meal_index === 0) ? 'border-gray-300' : 'border-black/50')}
        inputStyle={{
            height: "100%",
            width: "100%",
            outline: "none",
            boxShadow: "none",
            paddingLeft: "16px",
            paddingRight: "16px",
            backgroundColor: light_bg,
            color: light_text,
        }}
        itemTemplate={itemTemplate}
        onChange={(e) => setSelectedItem(e.value)}
        onFocus={() => setIs_focus(true)}
        onBlur={() => setIs_focus(false)}/>
    )
}

const Portion_ref = ({portion, portion_weight, group, text_color_code}) => {
  const controls = useAnimationControls();
  const ani_obj = {
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    }
  }

  useEffect(() => {
    controls.start('hidden');

    setTimeout(() => {
      controls.start('visible');
    }, 500);
  }, [portion, portion_weight, group]);

  return (
    <motion.div
    variants={ani_obj}
    initial='hidden'
    animate={controls}>
      <p className='notoser-reg text-xs mt-1.5' style={{color: text_color_code}}>ปริมาตรอ้างอิงต่อ 1 ส่วนของส่วนประกอบนี้ คือ {portion} ({(portion_weight === 0) ? '-' : portion_weight} กรัม) {group ? (group.includes('เนื้อสัตว์')) ? '(หนึ่งส่วนเท่ากับเนื้อสัตว์สุก 2 ช้อนโต๊ะ หรือ เนื้อสัตว์ดิบ 3 ช้อนโต๊ะ)' : '' : ''}</p>
    </motion.div>
  )
}

const Adding_food_panel_custom = ({isShow, meal_index, handle_custom_height_change, handle_update}) => {
    const [ height, setHeight ] = useState(0);
    const [ energy, setEnergy ] = useState(0);
    const [ CHO, setCHO ] = useState(0);
    const [ PRO, setPRO ] = useState(0);
    const [ FAT, setFAT ] = useState(0);
    const [ sodium, setSodium ] = useState(0);
    const [ portion, setPortion ] = useState(null);
    const [ portion_weight, setPortion_weight ] = useState(-1);
    const [ portion_other, setPortion_other ] = useState(null);
    const [ no_data, setNo_data ] = useState(false);
    const [ open_portion_other, setOpen_portion_other ] = useState(false);
  
    const color_code_white = '#ffffff';
    const color_code_black = '#000000';
    const [ light_text, setLight_text ] = useState(color_code_black);
    const [ light_bg, setLight_bg ] = useState(color_code_white);
    const [ light_text_disable, setLight_text_disable ] = useState('#333333');
  
    const [ is_energy, setIs_energy ] = useState(true);
    const [ is_CHO, setIs_CHO ] = useState(false);
    const [ is_PRO, setIs_PRO ] = useState(false);
    const [ is_FAT, setIs_FAT ] = useState(false);
    const [ is_sodium, setIs_sodium ] = useState(false);
  
    const ref_component = useRef(null);
    const ref_NA = useRef(null);
    const ref_portion = useRef(null);
    const ref_portion_weight = useRef(null);
    const ref_nutrient = useRef(null);
    const ref_portion_other = useRef(null);
  
    useEffect(() => {
      if(ref_component.current) {
        const new_height = isShow ? ref_component.current.scrollHeight : 0;
  
        setHeight(new_height);
        handle_custom_height_change(new_height);
      }
  
      if(!isShow) {
        reset_components();
        reset_active_nutrition_box();
        reset_states();
      }
    }, [isShow]);
  
    useEffect(() => {
      setLight_text(meal_index === -1 ? color_code_black : color_codes[meal_index]?.light_text);
      setLight_bg(meal_index === -1 ? color_code_white : color_codes[meal_index]?.light_bg);
      setLight_text_disable(meal_index === -1 ? color_code_white : color_codes[meal_index]?.light_text_disable);
    }, [meal_index]);
  
    useEffect(() => {
      if(ref_nutrient.current) {
        if(is_energy) {
          ref_nutrient.current.value = energy;
        }
        
        if(is_CHO) {
          ref_nutrient.current.value = CHO;
        }
  
        if(is_PRO) {
          ref_nutrient.current.value = PRO;
        }
  
        if(is_FAT) {
          ref_nutrient.current.value = FAT;
        }
  
        if(is_sodium) {
          ref_nutrient.current.value = sodium;
        }
      }

      set_no_data_check();
      set_no_data_input();
    }, [is_energy, is_CHO, is_PRO, is_FAT, is_sodium]);
  
    useEffect(() => {
      const obj = {
        energy: energy,
        CHO: CHO,
        PRO: PRO,
        FAT: FAT,
        sodium: sodium,
        portion: portion,
        portion_other: portion_other,
        portion_weight: portion_weight,
      }
  
      handle_update(obj);

      if(ref_nutrient.current && ref_NA.current) {
        let nutrient_value = 0;

        if(is_energy) { nutrient_value = energy; }
        if(is_CHO) { nutrient_value = CHO; }
        if(is_PRO) { nutrient_value = PRO; }
        if(is_FAT) { nutrient_value = FAT; }
        if(is_sodium) { nutrient_value = sodium; }

        ref_nutrient.current.value = (ref_NA.current.checked) ? '' : nutrient_value;
      }
    }, [energy, CHO, PRO, FAT, sodium, portion, portion_weight, portion_other]);
    
    function set_no_data_check() {
      if(ref_NA.current) {
        ref_NA.current.checked = no_data;
      }
    }

    function set_no_data_input() {
      if(ref_nutrient.current) {
        //to set value into input, it has to unlock disabled first
        ref_nutrient.current.disabled = false;

        if(is_energy) {
          ref_nutrient.current.value = (energy === -1) ? '' : energy;
        }

        if(is_CHO) {
          ref_nutrient.current.value = (CHO === -1) ? '' : CHO;
        }

        if(is_PRO) {
          ref_nutrient.current.value = (PRO === -1) ? '' : PRO;
        }

        if(is_FAT) {
          ref_nutrient.current.value = (FAT === -1) ? '' : FAT;
        }

        if(is_sodium) {
          ref_nutrient.current.value = (sodium === -1) ? '' : sodium;
        }

        ref_nutrient.current.disabled = no_data;
      }
    }
  
    function reset_active_nutrition_box() {
      setIs_energy(false);
      setIs_CHO(false);
      setIs_PRO(false);
      setIs_FAT(false);
      setIs_sodium(false);
    }
  
    function reset_components() {
      if(ref_nutrient.current) {
        ref_nutrient.current.value = '';
      }
  
      if(ref_portion.current) {
        ref_portion.current.selectedIndex = 0;
      }
  
      if(ref_portion_other.current) {
        ref_portion_other.current.value = '';
      }
  
      if(ref_portion_weight.current) {
        ref_portion_weight.current.value = '';
      }
    }
  
    function reset_states(no_data) {
      //do not set no_data here
      setEnergy(no_data ? -1 : 0);
      setCHO(no_data ? -1 : 0);
      setPRO(no_data ? -1 : 0);
      setFAT(no_data ? -1 : 0);
      setSodium(no_data ? -1 : 0);
      setPortion(null);
      setPortion_weight(-1);
      setPortion_other(null);
      setOpen_portion_other(false);
  
      setIs_energy(true);
      setIs_CHO(false);
      setIs_PRO(false);
      setIs_FAT(false);
      setIs_sodium(false);
    }
  
    const Nutrient_Box = ({nutrient, unit, value, active, meal_index, handle_click}) => {
      return (
        (meal_index === 0) ?
          <div className={'w-36 h-36 rounded-xl flex flex-col justify-center cursor-pointer ' + (active ? 'border-2 border-orange-primary outline-orange-primary' : 'border border-gray-200')}
          onClick={handle_click}>
            <p className='notoser-bold text-md text-center text-web-black'>{nutrient}</p>
            <p className='notoser-reg text-center text-web-black mt-1.5'>{(value === -1) ? 'N/A' : value}<span className={(value === -1) ? 'hidden' : 'block'}> {unit}</span></p>
          </div>
        :
          <div className={'w-36 h-36 rounded-xl flex flex-col justify-center border cursor-pointer transition-color duration-300 ' + (active ? 'bg-white text-web-black' : 'border border-gray-200')}
          onClick={handle_click}>
            <p className='notoser-bold text-md text-center text-web-black'>{nutrient}</p>
            <p className='notoser-reg text-center text-web-black mt-1.5'>{(value === -1) ? 'N/A' : value}<span className={(value === -1) ? 'hidden' : 'block'}> {unit}</span></p>
          </div>
      )
    }
  
    return (
      <div ref={ref_component} className='overflow-hidden w-full transition-all duration-300 ease-in-out' style={{ height: height + 'px' }}>
        <div className='border border-gray-200 rounded-md px-4 py-6'>
          <p className='notoser-bold text-web-black text-center text-sm'>กำหนดสารอาหารต่อหน่วยบริโภค</p>
  
          <div className='w-fit h-fit flex gap-5 my-4 mx-auto'>
            <Nutrient_Box nutrient={'Energy'} unit={'kcal'} value={energy} active={is_energy} meal_index={meal_index}
            handle_click={() => {
              setNo_data(energy === -1);
              reset_active_nutrition_box();
              setIs_energy(!is_energy);
            }}/>
  
            <Nutrient_Box nutrient={'Carbohydrate'} unit={'g'} value={CHO} active={is_CHO} meal_index={meal_index}
            handle_click={() => {
              setNo_data(CHO === -1);
              reset_active_nutrition_box();
              setIs_CHO(!is_CHO);
            }}/>
  
            <Nutrient_Box nutrient={'Protein'} unit={'g'} value={PRO} active={is_PRO} meal_index={meal_index}
            handle_click={() => {
              setNo_data(PRO === -1);
              reset_active_nutrition_box();
              setIs_PRO(!is_PRO);
            }}/>
  
            <Nutrient_Box nutrient={'Fat'} unit={'g'} value={FAT} active={is_FAT} meal_index={meal_index}
            handle_click={() => {
              setNo_data(FAT === -1);
              reset_active_nutrition_box();
              setIs_FAT(!is_FAT);
            }}/>
  
            <Nutrient_Box nutrient={'Sodium'} unit={'g'} value={sodium} active={is_sodium} meal_index={meal_index}
            handle_click={() => {
              setNo_data(sodium === -1);
              reset_active_nutrition_box();
              setIs_sodium(!is_sodium);
            }}/>
          </div>
  
          <div className='w-full h-fit border border-gray-200 rounded-md px-4 py-3'>
            <label className="inline-flex items-center cursor-pointer gap-2">
              <span className="notoser-reg text-web-black">ไม่มีข้อมูลของสารอาหารนี้</span>

              <input ref={ref_NA} type="checkbox" value="" className="sr-only peer"
              onChange={(e) => {
                if(is_energy) {
                  setEnergy(e.target.checked ? -1 : 0);
                }
  
                if(is_CHO) {
                  setCHO(e.target.checked ? -1 : 0);
                }
  
                if(is_PRO) {
                  setPRO(e.target.checked ? -1 : 0);
                }
  
                if(is_FAT) {
                  setFAT(e.target.checked ? -1 : 0);
                }
  
                if(is_sodium) {
                  setSodium(e.target.checked ? -1 : 0);
                }

                setNo_data(e.target.checked);
              }}></input>

              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-orange-primary"></div>
            </label>
          </div>
  
          <div className='w-full h-fit border border-gray-200 rounded-md px-4 py-8 my-4'>
            <p className='notoser-reg text-web-black mb-4'>โปรดระบุปริมาณสารอาหารต่อหนึ่งหน่วยบริโภค<sup className='notoser-reg' style={{fontSize: 10}}><i>require</i></sup></p>
  
            <div className={'w-full h-fit flex mt-4 ' + (no_data ? 'opacity-50 cursor-not-allowed' : '')}>
              <input ref={ref_nutrient} type='number' placeholder={'โปรดระบุปริมาณของส่วนประกอบนี้'} style={{backgroundColor: light_bg, color: light_text, borderColor: light_text_disable}} disabled={no_data}
              className={'border border-gray-200 rounded-l-md w-full h-10 px-4 transition-color duration-500 placeholder-black/50 ' + ((no_data) ? 'opacity-50 cursor-not-allowed ' : (meal_index === 0 ? 'focus:border-orange-primary focus:outline-orange-primary ' : 'focus:border-white focus:outline-none '))}
              onFocus={() => ref_nutrient.current.value = ''}
              onChange={(e) => {
                if(is_energy) {
                  setEnergy(e.target.value === '' ? 0 : e.target.value);
                }
  
                if(is_CHO) {
                  setCHO(e.target.value === '' ? 0 : e.target.value);
                }
  
                if(is_PRO) {
                  setPRO(e.target.value === '' ? 0 : e.target.value);
                }
  
                if(is_FAT) {
                  setFAT(e.target.value === '' ? 0 : e.target.value);
                }
  
                if(is_sodium) {
                  setSodium(e.target.value === '' ? 0 : e.target.value);
                }
              }}></input>
          
              <div className='w-fit h-10 border border-gray-300 rounded-r-md px-4 flex justify-center items-center'>ส่วน</div>
            </div>
          </div>
  
          <div className='w-full h-fit grid grid-cols-2 gap-5 border border-gray-200 rounded-md px-4 py-8 my-4'>
            <div className='w-full h-fit'>
              <p className='notoser-reg text-web-black'>โปรดระบุ <span className='underline'>หน่วยอ้างอิง</span> ต่อปริมาณหนึ่งส่วน<sup className='notoser-reg' style={{fontSize: 10}}><i>require</i></sup></p>
  
              <select ref={ref_portion} style={{backgroundColor: light_bg, color: light_text, borderColor: light_text_disable}} disabled={no_data}
              className={'bg-white border rounded-md w-full h-10 px-4 mt-4 ' + (meal_index === 0 ? 'focus:border-orange-bold focus:outline-orange-primary ' : 'focus:border-white focus:outline-none ') + (no_data ? 'opacity-50 cursor-not-allowed' : '')}
              onChange={(e) => {
                setPortion(e.target.value);
                setOpen_portion_other(e.target.value === 'อื่นๆ');
  
                if(e.target.value !== 'อื่นๆ') {
                  ref_portion_other.current.value = '';
                }
              }}>
                <option value={'NA'}>โปรดเลือก...</option>
                <option value={'ทัพพี'}>ทัพพี</option>
                <option value={'ช้อนโต๊ะ'}>ช้อนโต๊ะ</option>
                <option value={'ช้อนชา'}>ช้อนชา</option>
                <option value={'แก้ว'}>แก้ว</option>
                <option value={'ครึ่งทัพพี'}>ครึ่งทัพพี</option>
                <option value={'ครึ่งช้อนโต๊ะ'}>ครึ่งช้อนโต๊ะ</option>
                <option value={'ครึ่งฟอง'}>ครึ่งฟอง</option>
                <option value={'ครึ่งถ้วย'}>ครึ่งถ้วย</option>
                <option value={'1/3หลอด'}>1/3หลอด</option>
                <option value={'ส่วน'}>ส่วน</option>
                <option value={'อื่นๆ'}>อื่นๆ (ระบุเอง)</option>
              </select>
  
              <input ref={ref_portion_other} type='type' placeholder={'กรณีตอบอื่นๆ โปรดระบุ'} 
              style={{backgroundColor: light_bg, color: light_text, borderColor: light_text_disable}} disabled={!open_portion_other}
              className={'bg-white border rounded-md w-full h-10 px-4 mt-2 transition-color duration-500 placeholder-black/50 ' + (meal_index === 0 ? 'focus:border-orange-primary focus:outline-orange-primary ' : 'focus:border-white focus:outline-none ') + (open_portion_other ? '' : 'opacity-50 cursor-not-allowed')}
              onChange={(e) => setPortion_other(e.target.value)}></input>
            </div>
  
            <div className='w-full h-fit'>
              <p className='notoser-reg text-web-black'>โปรดระบุ <span className='underline'>หน่วยอ้างอิง</span> ต่อปริมาณหนึ่งส่วน<sup className='notoser-reg' style={{fontSize: 10}}><i>require</i></sup></p>
  
              <div className={'w-full h-fit flex mt-4 ' + (no_data ? 'opacity-50 cursor-not-allowed' : '')}>
                <input ref={ref_portion_weight} type='number' placeholder={'ระบุเป็นตัวเลขหรือเว้นไว้หากไม่มีข้อมูล'} 
                style={{backgroundColor: light_bg, color: light_text, borderColor: light_text_disable}} disabled={no_data}
                className={'bg-white border rounded-l-md w-full h-10 px-4 placeholder-black/50 ' + (meal_index === 0 ? 'focus:border-orange-primary focus:outline-orange-primary' : 'focus:border-white focus:outline-none')}
                onChange={(e) => setPortion_weight(e.target.value)}></input>
  
                <div className='w-fit h-10 border border-gray-300 rounded-r-md px-4 flex justify-center items-center'>กรัม</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

const Adding_food_panel = forwardRef(({food_items, edit_item, handle_custom_height_change, handle_meal_change, handle_update}, ref) => {
  const [ db_food_names, setDB_food_names ] = useState([]);
  const [ food_name, setFood_name ] = useState('');
  const [ source, setSource ] = useState('NA');
  const [ meal_index, setMeal_index ] = useState(0);
  const [ comp_name, setComp_name ] = useState('');
  const [ food_amount, setFood_amount ] = useState(0);
  const [ group, setGroup ] = useState('NA');
  const [ is_custom, setIs_custom ] = useState(false);
  const [ custom_na, setCustom_na ] = useState(false);
  const [ custom_portion, setCustom_portion ] = useState('');
  const [ custom_portion_other, setCustom_portion_other ] = useState('');
  const [ custom_portion_weight, setCustom_portion_weight ] = useState(0);
  const [ note, setNote ] = useState('');

  const [ portion, setPortion ] = useState('-');
  const [ portion_weight, setPortion_weight ] = useState(0);
  const [ energy, setEnergy ] = useState(0);
  const [ CHO, setCHO ] = useState(0);
  const [ PRO, setPRO ] = useState(0);
  const [ FAT, setFAT ] = useState(0);
  const [ sodium, setSodium ] = useState(0);

  const [ id_counter, setID_counter ] = useState(0); //to identify item when edit

  const color_code_white = '#ffffff';
  const color_code_black = '#000000';
  const [ theme_color, setTheme_color ] = useState(color_code_white);
  const [ light_text, setLight_text ] = useState(color_code_black);
  const [ light_bg, setLight_bg ] = useState(color_code_white);
  const [ light_text_disable, setLight_text_disable ] = useState('#333333');

  const ref_food_name = useRef(null);
  const ref_food_source = useRef(null);
  const ref_meal = useRef(null);
  const ref_food_exchange = useRef(null);
  const ref_amount = useRef(null);
  const ref_note = useRef(null);

  useEffect(() => {
    if(ref_amount.current) {
        ref_amount.current.value = food_amount;
    }
  }, [food_amount, ref_amount]);

  useEffect(() => {
      if(food_items.length > 0) {
          let result = [];
          food_items.map(x => result.push(x.name));
          setDB_food_names(result);
      }
  }, [food_items]);

  useEffect(() => {
      if(ref_food_exchange.current) {
          if(group !== 'NA') {
          const options = Array.from(ref_food_exchange.current.options);
          const select_index = options.findIndex((x) => x.value === group);
          ref_food_exchange.current.selectedIndex = select_index;
          }
      }
  }, [group]);

  useEffect(() => {
      if(ref_meal.current) {
          const selectedIndex = ref_meal.current.value;
          setTheme_color(selectedIndex === -1 ? color_code_white : color_codes[selectedIndex].color);
          setLight_text(selectedIndex === -1 ? color_code_white : color_codes[selectedIndex].light_text);
          setLight_bg(selectedIndex === -1 ? color_code_white : color_codes[selectedIndex].light_bg);
          setLight_text_disable(selectedIndex === -1 ? color_code_white : color_codes[selectedIndex].light_text_disable);

          handle_meal_change(meal_index);
      }
  }, [meal_index, ref_meal]);

  useEffect(() => {
    refresh_modal();
  }, [edit_item]);

  useImperativeHandle(ref, () => ({
    close: () => reset(),
    refresh: () => refresh_modal(),
  }));

  function refresh_modal() {
    if(edit_item) {
      if(ref_food_name.current) {
          ref_food_name.current.value = edit_item.food_name;
      }

      if(ref_food_source.current) {
          const options = ref_food_source.current.options;
          const index = Array.from(options).map(option => option.value).findIndex(x => x === edit_item.source);
          ref_food_source.current.selectedIndex = index;
      }

      if(ref_meal.current) {
          ref_meal.current.selectedIndex = edit_item.meal_index;
      }

      if(ref_food_exchange.current) {
          const options = ref_food_exchange.current.options;
          const index = Array.from(options).map(option => option.value).findIndex(x => x === edit_item.group);
          ref_food_exchange.current.selectedIndex = index;
      }

      if(ref_amount.current) {
          ref_amount.current.value = edit_item.food_amount;
      }

      if(ref_note.current) {
          ref_note.current.value = edit_item.note;
      }

      setFood_name(edit_item.food_name);
      setSource(edit_item.source);
      setComp_name(edit_item.comp_name);
      setGroup(edit_item.group);
      setMeal_index(edit_item.meal_index);
      setFood_amount(edit_item.food_amount);
      setIs_custom(edit_item.is_custom);
      setCustom_na(edit_item.custom_na);
      setCustom_portion(edit_item.custom_portion);
      setCustom_portion_other(edit_item.custom_portion_other);
      setCustom_portion_weight(edit_item.custom_portion_weight);
      setNote(edit_item.note);

      setPortion(edit_item.portion);
      setPortion_weight(edit_item.portion_weight);
      setEnergy(edit_item.energy);
      setCHO(edit_item.CHO);
      setPRO(edit_item.PRO);
      setFAT(edit_item.FAT);
      setSodium(edit_item.sodium);
    }
  }

  function getUpdate_obj() {
      const obj = {
        id: id_counter,
        food_name: food_name,
        source: source,
        meal_index: meal_index,
        comp_name: comp_name,
        food_amount: food_amount,
        group: group,
        portion: portion,
        portion_weight: portion_weight,
        
        energy: energy,
        CHO: CHO,
        PRO: PRO,
        FAT: FAT,
        sodium: sodium,

        is_custom: is_custom,
        custom_na: custom_na,
        custom_portion: custom_portion,
        custom_portion_other: custom_portion_other,
        custom_portion_weight: custom_portion_weight,
        note: note,
      };

      setID_counter(id_counter + 1);
      return obj;
  }

  function reset() {
      setFood_name('');
      setSource('NA');
      setMeal_index(0);
      setComp_name('');
      setFood_amount(0);
      setGroup('NA');
      setIs_custom(false);
      setCustom_na(false);
      setCustom_portion('');
      setCustom_portion_other('');
      setCustom_portion_weight(0);
      setNote('');

      setPortion('-');
      setPortion_weight(0);
      setEnergy(0);
      setCHO(0);
      setPRO(0);
      setFAT(0);
      setSodium(0);

      if(ref_food_name.current) {
          ref_food_name.current.value = '';
      }

      if(ref_food_source.current) {
          ref_food_source.current.selectedIndex = 0;
      }

      if(ref_meal.current) {
          ref_meal.current.selectedIndex = 0;
      }

      if(ref_food_exchange.current) {
          ref_food_exchange.current.selectedIndex = 0;
      }

      if(ref_amount.current) {
          ref_amount.current.value = 0;
      }

      if(ref_note.current) {
          ref_note.current.value = '';
      }
  }

  function reset_when_submit() {
      setComp_name('');
      setFood_amount(0);
      setGroup('NA');
      setIs_custom(false);
      setCustom_na(false);
      setCustom_portion('');
      setCustom_portion_other('');
      setCustom_portion_weight(0);
      setNote('');

      setPortion('-');
      setPortion_weight(0);
      setEnergy(0);
      setCHO(0);
      setPRO(0);
      setFAT(0);
      setSodium(0);

      if(ref_food_exchange.current) {
          ref_food_exchange.current.selectedIndex = 0;
      }

      if(ref_amount.current) {
          ref_amount.current.value = 0;
      }

      if(ref_note.current) {
          ref_note.current.value = '';
      }
  }

  function check_conditions_to_submit() {
      if(food_name === '') {
          alert('ยังไม่ได้ระบุชื่ออาหาร กรุณาระบุก่อนกดยืนยัน');
          return false
      }

      if(source === 'NA') {
          alert('ยังไม่ได้ระบุแหล่งที่มาของอาหาร กรุณาระบุก่อนกดยืนยัน');
          return false
      }

      if(meal_index === 0) {
          alert('ยังไม่ได้ระบุมื้ออาหาร กรุณาระบุก่อนกดยืนยัน');
          return false
      }

      if(comp_name === '' || comp_name === null) {
          alert('ยังไม่ได้ระบุชื่อส่วนประกอบ กรุณาระบุก่อนกดยืนยัน');
          return false
      }

      if(group === 'NA') {
          alert('ยังไม่ได้ระบุหมวดอาหารแลกเปลี่ยน กรุณาระบุก่อนกดยืนยัน');
          return false
      }

      if(food_amount === 0) {
          alert('ยังไม่ได้ระบุปริมาณส่วนประกอบ กรุณาระบุก่อนกดยืนยัน');
          return false
      }

      if(is_custom) {
          if(custom_portion === '') {
          alert('ยังไม่ได้ระบุหน่วยอ้างอิง(กรณีระบุหมวดอาหารอื่นๆ) กรุณาระบุก่อนกดยืนยัน');
          return false
          }
      }

      return true;
  }

  return (
    <div className='w-full h-fit px-8 md:px-12 pt-6 pb-10 transition-color duration-500' style={{backgroundColor: theme_color}}>
      <div className='w-full h-fit grid grid-cols-6 gap-3'>
        <div className='w-full h-fit col-span-4'>
          <p className='notoser-reg my-4' style={{color: light_text}}>1. โปรดระบุรายการอาหาร<sup className='notoser-reg' style={{fontSize: 10}}><i>require</i></sup></p>
          <input ref={ref_food_name} type='text' placeholder='โปรดระบุรายการอาหาร' style={{backgroundColor: light_bg, color: light_text, borderColor: light_text_disable}}
          onChange={(e) => setFood_name(e.target.value)}
          className={'bg-white border rounded-md w-full h-10 px-4 pt-1 transition-color duration-500 placeholder-black/50 ' + (meal_index === 0 ? 'focus:border-orange-primary focus:outline-orange-primary' : 'focus:border-white focus:outline-none')}></input>
        </div>

        <div className='w-full h-full flex items-end'>
          <select ref={ref_food_source} style={{backgroundColor: light_bg, color: light_text, borderColor: light_text_disable}}
          className={'bg-white border rounded-md w-full h-10 px-4 transition-color duration-500 ' + (meal_index === 0 ? 'focus:border-orange-bold focus:outline-orange-primary' : 'focus:border-white focus:outline-none')}
          onChange={(e) => setSource(e.target.value)}>
            <option value={'NA'}>โปรดเลือก...</option>
            <option value={'ซื้อ'}>ซื้อ</option>
            <option value={'ทำเอง'}>ทำเอง</option>
          </select>
        </div>

        <div className='w-full h-full flex items-end'>
          <select ref={ref_meal} style={{backgroundColor: light_bg, color: light_text, borderColor: light_text_disable}}
          className={'bg-white border rounded-md w-full h-10 px-4 transition-color duration-500 ' + (meal_index === 0 ? 'focus:border-orange-primary focus:outline-orange-primary' : 'focus:border-white focus:outline-none')}
          onChange={(e) => setMeal_index(parseInt(e.target.value))}>
            {
              color_codes.map((x, i) => {
                return (
                  <option key={i} value={i}>{x.meal_th}</option>
                )
              })
            }
          </select>
        </div>
      </div>

      <div className='w-full h-fit grid grid-cols-6 gap-3'>
        <div className='w-full h-fit col-span-5'>
          <p className='notoser-reg text-web-black my-4' style={{color: light_text}}>2. โปรดระบุส่วนประกอบ<sup className='notoser-reg' style={{fontSize: 10}}><i>require</i></sup></p>
          
          <AutoCompletInput 
          value={comp_name}
          original_items={db_food_names} 
          meal_index={meal_index}
          handle_change={(value) => {
            setComp_name(value);

            const index = food_items.findIndex((x) => x.name === value);
            
            if(index === -1) {
              setPortion('-');
              setPortion_weight(0);
              setGroup('NA');
              setEnergy(0);
              setCHO(0);
              setPRO(0);
              setFAT(0);
              setSodium(0);
            }
            else {
              const target_obj = {...food_items[index]};
              setPortion(target_obj.portion);
              setPortion_weight(parseFloat(target_obj.weight));
              setGroup(target_obj.group);
              setEnergy(parseFloat(target_obj.energy));
              setCHO(parseFloat(target_obj.CHO));
              setPRO(parseFloat(target_obj.PRO));
              setFAT(parseFloat(target_obj.FAT));
              setSodium(parseFloat(target_obj.sodium));
            }
          }}/>
        </div>

        <div className='w-full h-full flex items-end'>
          <select ref={ref_food_exchange} style={{backgroundColor: light_bg, color: light_text, borderColor: light_text_disable}}
          className={'border rounded-md w-full h-10 px-4 transition-color duration-500 ' + ((group === 'NA') ? 'bg-white focus:border-orange-bold focus:outline-orange-primary' : 'bg-green-100 border-green-500 focus:bg-white focus:border-gray-200 focus:text-web-black focus:outline-orange-primary')}
          onChange={(e) => {
            setGroup(e.target.value);
            setIs_custom(e.target.value === 'custom');
          }}>
            <option value={'NA'}>โปรดเลือก...</option>

            {
              foodExchange.map((x, i) => {
                return (
                  <option key={i} value={x.category}>{x.category}</option>
                )
              })
            }

            <option value={'custom'}>กำหนดเอง...</option>
          </select>
        </div>
      </div>

      <div className={'w-full ' + (is_custom ? 'h-6' : 'h-0')}></div>

      <Adding_food_panel_custom isShow={is_custom} meal_index={meal_index}
      handle_custom_height_change={(new_height) => {
        handle_custom_height_change(new_height);
      }}
      handle_update={(customs) => {
        setEnergy(customs.energy);
        setCHO(customs.CHO);
        setPRO(customs.PRO);
        setFAT(customs.FAT);
        setSodium(customs.sodium);
        setCustom_portion(customs.portion);
        setCustom_portion_other(customs.portion_other);
        setCustom_portion_weight(customs.portion_weight);
        setCustom_na(customs.no_data);
      }}/>

      <p className='notoser-reg text-web-black my-4' style={{color: light_text}}>3. โปรดระบุปริมาณ<sup className='notoser-reg' style={{fontSize: 10}}><i>require</i></sup></p>
      <div className='w-full h-fit flex'>
        <input ref={ref_amount} type='number' placeholder={'โปรดระบุสัดปริมาณของส่วนประกอบนี้'} style={{backgroundColor: light_bg, color: light_text, borderColor: light_text_disable}}
        onFocus={() => ref_amount.current.value = ''}
        onChange={(e) => setFood_amount(e.target.value)}
        className={'bg-white border rounded-l-md w-full h-10 px-4 transition-color duration-500 ' + (meal_index === 0 ? 'focus:border-orange-primary focus:outline-orange-primary' : 'focus:border-white focus:outline-none')}></input>
    
        <div className='w-32 h-10 border border-gray-300 rounded-r-md px-4 flex items-center' style={{backgroundColor: light_bg, color: light_text, borderColor: light_text_disable}}>{(portion === '-') ? 'ส่วน' : portion}</div>
      </div>
      <Portion_ref portion={portion} portion_weight={portion_weight} group={group} text_color_code={light_text}/>

      <p className='notoser-reg text-web-black my-4' style={{color: light_text}}>4. หมายเหตุ<sup className='notoser-reg' style={{fontSize: 10}}><i>optional</i></sup></p>
      <textarea ref={ref_note} rows={5} placeholder='โปรดระบุหมายเหตุ หากไม่มีให้เว้นว่าง' style={{backgroundColor: light_bg, color: light_text, borderColor: light_text_disable}}
      onChange={(e) => setNote(e.target.value)}
      className={'w-full border rounded-md resize-none px-4 py-2 transition-color duration-500 placeholder-black/50 ' + (meal_index === 0 ? 'focus:border-orange-primary focus:outline-orange-primary' : 'focus:border-white focus:outline-none')}></textarea>

      { /* Buttons for all conditions except night */ }
      <div className={'w-full h-fit flex justify-center gap-5 mt-6 ' + (meal_index === 0 ? 'block' : 'hidden')}>
        <button className='notoser-semi w-60 h-fit bg-white text-orange-primary border border-orange-primary px-10 py-3 rounded-md'
        onClick={() => reset()}>รีเซ็ตค่า</button>

        <button className='notoser-semi w-60 h-fit bg-orange-primary text-white px-10 py-3 rounded-md hover:bg-orange-bold'
        onClick={() => {
          const pass = check_conditions_to_submit();

          if(pass) {
            handle_update(getUpdate_obj());
            reset_when_submit();
          }
        }}>{edit_item ? 'แก้ไขข้อมูล' : 'เพิ่มข้อมูล'}</button>
      </div>

      { /* Buttons for night condition */ }
      <div className={'w-full h-fit flex justify-center gap-5 mt-6 ' + (meal_index === 0 ? 'hidden' : 'block')}>
        <button className='notoser-semi w-60 h-fit px-10 py-3 rounded-md hover:bg-orange-bold'
        style={{backgroundColor: light_text, color: theme_color}}
        onClick={() => reset()}>รีเซ็ตค่า</button>

        <button className='notoser-semi w-60 h-fit px-10 py-3 rounded-md hover:bg-orange-bold'
        style={{backgroundColor: light_text, color: theme_color}}
        onClick={() => {
          const pass = check_conditions_to_submit();

          if(pass) {
            handle_update(getUpdate_obj());
            reset_when_submit();
          }
        }}>{edit_item ? 'แก้ไขข้อมูล' : 'เพิ่มข้อมูล'}</button>
      </div>
    </div>
  )
})

export default Adding_food_panel