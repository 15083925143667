import React, { useEffect, useRef, useState } from 'react';
import logo from '../../../../assets/logo/logo.jpg';
import Form_NutritionIntake_Nutrient_Input from './Form_NutritionIntake_Nutrient_Input';

const Input_Hospital_Number = ({id, placeholder, value, handle_onchange}) => {
    const [ focus, setFocus ] = useState(false);
    const [ correct, setCorrect ] = useState(false);
    const [ input_value, setInput_value ] = useState('');
    const [ note, setNote ] = useState('');

    const ref = useRef(null);

    useEffect(() => {
        if(value !== '') {
            setInput_value(value);
            setFocus(true);
        }
    }, [value]);

    useEffect(() => {
        validate_input();
    }, [input_value]);

    function validate_input() {
        setFocus(false);

        if(ref.current && ref.current.value.length > 0) {
            if(!ref.current.value.includes("/")) {
                setCorrect(false);
                setNote("ขาดเครื่องหมาย /");
                return;
            }
    
            const components = ref.current.value.split("/");
            if(!components.length === 2) {
                setCorrect(false);
                setNote("มีเครื่องหมาย / มากกว่า 1 ตำแหน่ง");
                return;
            }
    
            if(components[0].length !== 5) {
                setCorrect(false);
                setNote("จำนวนตัวเลขไม่ถูกต้อง");
                return;
            }
    
            if(components[1].length !== 2) {
                setCorrect(false);
                setNote("จำนวนตัวเลขไม่ถูกต้อง");
                return;
            }
        }

        setNote('');
        setCorrect(true);
    }

    return (
        <div className='w-full h-fit'>
            <input type='text' 
            placeholder={placeholder} 
            ref={ref} id={id} name={id}
            value={input_value}
            disabled={true}
            className={'border focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-1 focus:bg-white cursor-not-allowed opacity-60 ' + ((input_value.length === 0) ? 'bg-white border-gray-200' : (!focus && correct) ? 'bg-green-100 border-green-500' : 'bg-red-100 border-red-500')}
            onChange={(e) => {
                //setInput_value(e.target.value);
                handle_onchange(e.target.value);
            }}
            onFocus={() => setFocus(true)} 
            onBlur={() => validate_input()}></input>

            <p className={'notoser-reg text-web-black text-xs text-red-500 ' + (note === '' ? 'hidden' : 'block')}><u>หมายเหตุ</u> :{note}</p>
        </div>
    )
}

const Group_Button_Sex = ({male, female, handle_onchange}) => {
    const [ select_male, setSelect_male ] = useState(false);
    const [ select_female, setSelect_female ] = useState(false);
  
    useEffect(() => {
      setSelect_male(male);
    }, [male]);
  
    useEffect(() => {
      setSelect_female(female);
    }, [female]);
  
    useEffect(() => {
      if(select_male) {
        //setSelect_female(false);
        handle_onchange(true);
      }
    }, [select_male]);
  
    useEffect(() => {
      if(select_female) {
        //setSelect_male(false);
        handle_onchange(false);
      }
    }, [select_female]);
  
    return (
      <>
        <p className='notoser-reg text-web-black my-4'>โปรดเลือกเพศ</p>
        <div className='w-full h-fit grid grid-cols-2'>
          <div className={'w-full h-fit px-4 py-2 border border-gray-200 rounded-l-md text-center cursor-pointer hover:bg-gray-500 hover:text-white ' + (select_male ? 'bg-gray-500 text-white' : '')} onClick={() => setSelect_male(true)}>เพศชาย</div>
          <div className={'w-full h-fit px-4 py-2 border border-gray-200 rounded-r-md text-center cursor-pointer hover:bg-gray-500 hover:text-white ' + (select_female ? 'bg-gray-500 text-white' : '')} onClick={() => setSelect_female(true)}>เพศหญิง</div>
        </div>
      </>
    )
}

const Select_ER_equations = ({options, handle_onchange, handle_height_change}) => {
    const [ selectedIndex, setSelectedIndex ] = useState(0);
    const [ simple_height, setSimple_height ] = useState(0);
    const [ activity_height, setActivity_height ] = useState(0);
  
    const [ isSimple20, setIsSimple20 ] = useState(false);
    const [ isSimple25, setIsSimple25 ] = useState(false);
    const [ isSimple30, setIsSimple30 ] = useState(false);
    const [ isSimple35, setIsSimple35 ] = useState(false);
    const [ isSimple40, setIsSimple40 ] = useState(false);
  
    const ref_simple = useRef(null);
    const ref_activity = useRef(null);
  
    useEffect(() => {
      if(options[selectedIndex] === "โปรดระบุสมการคำนวณพลังงาน...") {
        setSimple_height(0);
        setActivity_height(0);
      }
      else if(options[selectedIndex] === "Simple equation") {
        if(ref_simple.current) {
          setSimple_height(ref_simple.current.scrollHeight);
          setActivity_height(0);
        }
      }
      else {
        if(ref_activity.current) {
          setSimple_height(0);
          setActivity_height(ref_activity.current.scrollHeight);
        }
      }
    }, [selectedIndex]);

    useEffect(() => {
      handle_height_change((options[selectedIndex] === "Simple equation") ? simple_height : activity_height);
    }, [simple_height, activity_height]);
  
    return (
      <>
        <p className='notoser-reg text-web-black my-4'>โปรดระบุสมการคำนวณพลังงานที่ต้องการต่อวัน</p>
        <select className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 mb-4' onChange={(e) => setSelectedIndex(e.target.selectedIndex)}>
            {
              options.map((x, i) => {
                return (
                  <option key={i} value={x}>{x}</option>
                )
              })
            }
        </select>
  
        <div ref={ref_simple} style={{height: simple_height + "px"}} 
        className={'w-full h-fit border border-gray-300 rounded-md px-6 overflow-hidden transition-all duration-500 ' + (options[selectedIndex] === "Simple equation" ? 'opacity-1' : 'opacity-0')}>
          <p className='notoser-bold text-web-black underline mt-6 mb-4'>ตัวเลือกเพิ่มเติม</p>
  
          <div className='w-full h-fit grid grid-cols-5 mb-6'>
            <div className={'w-full h-10 border border gray-300 flex justify-center items-center cursor-pointer hover:bg-gray-500 hover:text-white rounded-l-md ' + (isSimple20 ? 'bg-gray-500 text-white' : 'text-web-black')} 
            onClick={() => {
              setIsSimple20(true);
              setIsSimple25(false);
              setIsSimple30(false);
              setIsSimple35(false);
              setIsSimple40(false);
              handle_onchange(selectedIndex, 20, -1)
            }}>20 kcal/kg</div>
  
            <div className={'w-full h-10 border border gray-300 flex justify-center items-center cursor-pointer hover:bg-gray-500 hover:text-white ' + (isSimple25 ? 'bg-gray-500 text-white' : 'text-web-black')} 
            onClick={() => {
              setIsSimple20(false);
              setIsSimple25(true);
              setIsSimple30(false);
              setIsSimple35(false);
              setIsSimple40(false);
              handle_onchange(selectedIndex, 25, -1);
            }}>25 kcal/kg</div>
  
            <div className={'w-full h-10 border border gray-300 flex justify-center items-center cursor-pointer hover:bg-gray-500 hover:text-white ' + (isSimple30 ? 'bg-gray-500 text-white' : 'text-web-black')} 
            onClick={() => {
              setIsSimple20(false);
              setIsSimple25(false);
              setIsSimple30(true);
              setIsSimple35(false);
              setIsSimple40(false);
              handle_onchange(selectedIndex, 30, -1);
            }}>30 kcal/kg</div>
  
            <div className={'w-full h-10 border border gray-300 flex justify-center items-center cursor-pointer hover:bg-gray-500 hover:text-white ' + (isSimple35 ? 'bg-gray-500 text-white' : 'text-web-black')} 
            onClick={() => {
              setIsSimple20(false);
              setIsSimple25(false);
              setIsSimple30(false);
              setIsSimple35(true);
              setIsSimple40(false);
              handle_onchange(selectedIndex, 35, -1);
            }}>35 kcal/kg</div>
  
            <div className={'w-full h-10 border border gray-300 flex justify-center items-center cursor-pointer hover:bg-gray-500 hover:text-white rounded-r-md ' + (isSimple40 ? 'bg-gray-500 text-white' : 'text-web-black')} 
            onClick={() => {
              setIsSimple20(false);
              setIsSimple25(false);
              setIsSimple30(false);
              setIsSimple35(false);
              setIsSimple40(true);
              handle_onchange(selectedIndex, 40, -1);
            }}>40 kcal/kg</div>
          </div>
        </div>
  
        <div ref={ref_activity} style={{height: activity_height + "px"}}
        className={'w-full h-fit border border-gray-300 rounded-md px-6 overflow-hidden transition-all duration-500 ' + (selectedIndex !== 0 && options[selectedIndex] !== "Simple equation" ? 'opacity-1' : 'opacity-0')}>
          <p className='notoser-bold text-web-black underline mt-6 mb-4'>ตัวเลือกเพิ่มเติม</p>
          
          <div className='w-full h-fit mb-6'>
            <Select_PAL handle_onchange={(value) => handle_onchange(selectedIndex, -1, value)}/>
          </div>
        </div>
      </>
    )
}

const Select_PAL = ({handle_onchange}) => {
    const options = [
      {
        option: 'ไม่นำมาคำนวณ (PAL 1.0)',
        value: 1,
      },
      {
        option: 'กิจกรรมออกแรงเบามาก/นอนติดเตียง (PAL 1.21)',
        value: 1.21,
      },
      {
        option: 'กิจกรรมเบา (PAL 1.4)',
        value: 1.4,
      },
      {
        option: 'กิจกรรมปานกลาง (PAL 1.7)',
        value: 1.7,
      },
      {
        option: 'กิจกรรมหนัก (PAL 2.0)',
        value: 2,
      },
    ]
  
    return (
      <>
        <p className='notoser-reg text-web-black my-4'>โปรดระบุระดับกิจกรรมทางกาย</p>
        <select onChange={(e) => handle_onchange(e.target.value)}
        className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 mb-4'>
            {
              options.map((x, i) => {
                return (
                  <option key={i} value={x.option}>{x.option}</option>
                )
              })
            }
        </select>
      </>
    )
}

const Select_Stress_factors = ({male, handle_onchange}) => {
    const stess_factor_female = [
      {
        stress: 'ไม่มี Stress factor',
        value: 1,
      },
      {
        stress: 'ค่าเฉลี่ย (1.25)',
        value: 1.25,
      },
      {
        stress: 'Burns (1.52)',
        value: 1.52,
      },
      {
        stress: 'Fistula (1.15)',
        value: 1.15,
      },
      {
        stress: 'Inflammatory bowel disease (1.12)',
        value: 1.12,
      },
      {
        stress: 'Infection (ค่าเฉลี่ย) (1.35)',
        value: 1.35,
      },
      {
        stress: 'Infection (with abscess) (1.39)',
        value: 1.39,
      },
      {
        stress: 'Infection (with sepsis) (1.27)',
        value: 1.27,
      },
      {
        stress: 'Infection (other) (1.39)',
        value: 1.39,
      },
      {
        stress: 'Leukemia/lymphoma (1.37)',
        value: 1.37,
      },
      {
        stress: 'Liver disease (1.11)',
        value: 1.11,
      },
      {
        stress: 'Pancreatic disease (1.21)',
        value: 1.21,
      },
      {
        stress: 'Solid tumor (1.25)',
        value: 1.25,
      },
      {
        stress: 'Surgery (general) (1.39)',
        value: 1.39,
      },
      {
        stress: 'Surgery (transplantation) (1.27)',
        value: 1.27,
      },
    ]
  
    const stess_factor_male = [
      {
        stress: 'ไม่มี Stress factor',
        value: 1,
      },
      {
        stress: 'ค่าเฉลี่ย (1.25)',
        value: 1.25,
      },
      {
        stress: 'Burns (1.64)',
        value: 1.64,
      },
      {
        stress: 'Inflammatory bowel disease (1.11)',
        value: 1.11,
      },
      {
        stress: 'Infection (ค่าเฉลี่ย) (1.20)',
        value: 1.2,
      },
      {
        stress: 'Infection (with abscess) (1.12)',
        value: 1.12,
      },
      {
        stress: 'Infection (with sepsis) (1.33)',
        value: 1.33,
      },
      {
        stress: 'Infection (other) (1.16)',
        value: 1.16,
      },
      {
        stress: 'Leukemia/lymphoma (1.27)',
        value: 1.27,
      },
      {
        stress: 'Liver disease (1.07)',
        value: 1.07,
      },
      {
        stress: 'Pancreatic disease (1.13)',
        value: 1.13,
      },
      {
        stress: 'Solid tumor (1.15)',
        value: 1.15,
      },
      {
        stress: 'Surgery (general) (1.20)',
        value: 1.2,
      },
      {
        stress: 'Surgery (transplantation) (1.19)',
        value: 1.19,
      },
    ];
  
    const [ options, setOptions ] = useState([]);
  
    useEffect(() => {
      if(male) {
        setOptions([...stess_factor_male]);
      }
      else {
        setOptions([...stess_factor_female]);
      }
    }, [male])
  
    return (
      <>
        <p className='notoser-reg text-web-black my-4'>โปรดระบุปัจจัยความเครียดจากการเจ็บป่วยหรือบาดเจ็บ (stress factor)</p>
        <select onChange={(e) => handle_onchange(e.target.value)}
        className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 mb-4'>
            {
              options.map((x, i) => {
                return (
                  <option key={i} value={x.value}>{x.stress}</option>
                )
              })
            }
        </select>
      </>
    )
}

const Nutrients_distribution = ({weight, energy_requirement, handle_update}) => {
    const [ percentCHO, setPercentCHO ] = useState(55);
    const [ percentPRO, setPercentPRO ] = useState(15);
    const [ percentFAT, setPercentFAT ] = useState(30);
  
    //available values include null, CHO, PRO, FAT
    const [ prev_nutr, setPrev_nutr ] = useState(null);
  
    const ref_PROkg = useRef(null);
    const [ val, setVal ] = useState(-1); //this value keep user's value that type into the input, use this value to handle event onblur and press enter button
  
    useEffect(() => {
      console.log(energy_requirement);
  
      if(ref_PROkg.current) {
        let result = 'N/A';
  
        if(weight !== -1 && energy_requirement !== -1) {
          const protein_requirement = (parseFloat(energy_requirement) * (percentPRO / 100)) / 4;
          result = Math.round((protein_requirement / weight) * 10) / 10;
        }
  
        ref_PROkg.current.value = result;
      }
    }, [weight, energy_requirement]);
  
    useEffect(() => {
      if(prev_nutr !== 'PROkg') {
        if(weight !== -1 && energy_requirement !== -1) {
          let PROkg = (energy_requirement * (percentPRO / 100)) / 4;
  
          PROkg /= weight;
          PROkg = Math.round(PROkg * 10) /10;
  
          ref_PROkg.current.value = PROkg;
        }
      }
    }, [prev_nutr]);
  
    useEffect(() => {
      handle_update(percentCHO, percentPRO, percentFAT);
    }, [percentCHO, percentPRO, percentFAT]);
  
    function update_distribution(nutrient, new_value) {
      //these variables is obj keeping nutrient and value
      //the key of nutrient is option
      //the key of value is value
      let current_nutrient = { option: nutrient, value: new_value };
      let prev_nutrient = get_nutrient(prev_nutr);
      let unrelated_nutrient = get_unrelated_nutrient(nutrient, prev_nutr);
  
      //if calculating for fist time, it's not important whatever nutrient prev and unrelated would be
      //but they have to be set here because it is important to set specific input to specific value
      //so, although prev and unrelated could be any nutrients, we must set them here
      const opts = [
        {
          option: "CHO",
          value: percentCHO,
        },
        {
          option: "PRO",
          value: percentPRO,
        },
        {
          option: "FAT",
          value: percentFAT,
        }
      ];
  
      if(prev_nutr === null) {
        for(let i = 0; i < opts.length; i++) {
          if(opts[i]['option'] !== nutrient) {
            prev_nutrient = opts[i];
          }
        }
  
        for(let i = 0; i < opts.length; i++) {
          if(opts[i]['option'] !== nutrient && opts[i]['option'] !== prev_nutrient['option']) {
            unrelated_nutrient = opts[i];
          }
        }
      } 
  
      let current_value = parseFloat(current_nutrient["value"]);
      let prev_value = parseFloat(prev_nutrient["value"]);
      let unrelated_value = parseFloat(unrelated_nutrient["value"]);
  
      //if this is calculated for first time, prev_nutr would be null
      if(prev_nutr === null) {
        if(current_value > 100) {
          current_value = 100;
          prev_value = 0;
          unrelated_value = 0;
        }
        else {
          const gap = Math.abs(get_nutrient(nutrient)['value'] - current_value);
          prev_value -= Math.round((gap / 2) * 10) / 10;
          unrelated_value = 100 - (current_value + prev_value);
        }
      }
      else {
        if(current_value > 100) {
          current_value = 100;
          prev_value = 0;
          unrelated_value = 0;
        }
        else {
          if(current_value + prev_value > 100) {
            unrelated_value = 0;
            prev_value = 100 - current_value;
          }
          else {
            unrelated_value = 100 - (current_value + prev_value);
          }
        }
      }
  
      current_nutrient["value"] = current_value;
      prev_nutrient["value"] = prev_value;
      unrelated_nutrient["value"] = unrelated_value;
  
      update_input(current_nutrient["option"], current_nutrient["value"]);
      update_input(prev_nutrient["option"], prev_nutrient["value"]);
      update_input(unrelated_nutrient["option"], unrelated_nutrient["value"]);
      setPrev_nutr(nutrient);
      setVal(-1); //to prevent this function triggered again, for example, user click 'Enter' to calcualte once, then user leave focus from input, then onBlur will trigger this function again. We have to check condition val !== -1
  
      function update_input(nutrient, value) {
        //if nutrient is null, do nothing, so input will not be updated
        if(nutrient === 'CHO') {
          setPercentCHO(value);
        }
        else if(nutrient === 'PRO' || nutrient === 'PROkg') {
          setPercentPRO(value);
        }
        else if(nutrient === 'FAT') {
          setPercentFAT(value);
        }
      }
  
      function get_nutrient(nutrient) {
        if(nutrient === 'CHO') {
          return {
            option: 'CHO',
            value: percentCHO,
          };
        }
        else if(nutrient === 'PRO' || nutrient === 'PROkg') {
          return {
            option: 'PRO',
            value: percentPRO,
          };
        }
        else if(nutrient === 'FAT') {
          return {
            option: 'FAT',
            value: percentFAT,
          };
        }
        else {
          return {
            option: null,
            value: -1,
          };
        }
      }
  
      function get_unrelated_nutrient(current_nutrient, previous_nutrient) {
        let result = {
          option: null,
          value: -1,
        };
  
        const opts = [
          {
            option: "CHO",
            value: percentCHO,
          },
          {
            option: "PRO",
            value: percentPRO,
          },
          {
            option: "FAT",
            value: percentFAT,
          }
        ];
  
        const temp_current = (current_nutrient === 'PROkg') ? 'PRO' : current_nutrient;
        const temp_prev = (prev_nutrient === 'PROkg') ? 'PRO' : previous_nutrient;
  
        //if not first calculation
        if(previous_nutrient !== null) {
          for(let i = 0; i < opts.length; i++) {
            const opt = opts[i]["option"];
  
            if(opt !== temp_current && opt !== temp_prev) {
              result["option"] = opts[i]["option"];
              result["value"] = opts[i]["value"];
            }
          }
        }
  
        return result;
      }
    }
  
    return (
      <>
        <p className='notoser-reg text-web-black my-4'>โปรดระบุสัดส่วนความต้องการสารอาหาร</p>
  
        <div className='w-full h-fit flex mt-4'>
          <div className='w-36 h-10 min-w-36 border border-gray-300 rounded-l-md px-4 flex justify-start items-center'>คาร์โบไฮเดรต</div>
  
          <Form_NutritionIntake_Nutrient_Input nutrient={'คาร์โบไฮเดรต'} percent={percentCHO} current_value={val}
          handle_onchange={(new_value) => setVal(new_value)}
          handle_calculate={(new_value) => update_distribution('CHO', new_value)}/>
  
          <div className='w-fit h-10 border border-gray-300 rounded-r-md px-4 flex justify-center items-center'>%TEE</div>
        </div>
  
        <div className='w-full h-fit flex my-2'>
          <div className='w-36 h-10 min-w-36 border border-gray-300 rounded-l-md px-4 flex justify-start items-center'>ไขมัน</div>
  
          <Form_NutritionIntake_Nutrient_Input nutrient={'ไขมัน'} percent={percentFAT} current_value={val}
          handle_onchange={(new_value) => setVal(new_value)}
          handle_calculate={(new_value) => update_distribution('FAT', new_value)}/>
  
          <div className='w-fit h-10 border border-gray-300 rounded-r-md px-4 flex justify-center items-center'>%TEE</div>
        </div>
  
        <div className='w-full h-fit grid grid-cols-2 mb-4'>
          <div className='w-full h-fit flex'>
            <div className='w-36 min-w-36 h-10 border border-gray-300 rounded-l-md px-4 flex justify-start items-center'>โปรตีน</div>
  
            <Form_NutritionIntake_Nutrient_Input nutrient={'โปรตีน'} percent={percentPRO} current_value={val}
            handle_onchange={(new_value) => setVal(new_value)}
            handle_calculate={(new_value) => update_distribution('PRO', new_value)}/>
  
            <div className='w-fit h-10 border border-gray-300 px-4 flex justify-center items-center'>%TEE</div>
          </div>
  
          <div className='w-full h-fit flex'>
            <input ref={ref_PROkg} type='text' placeholder='โปรดระบุสัดส่วนโปรตีน' onFocus={(e) => e.target.value = ''} disabled={weight === -1 || energy_requirement === -1}
            className={'text-gray-500 border border-gray-300 focus:border-orange-primary focus:outline-orange-primary w-full h-10 px-4 pt-1 ' + ((weight === -1 || energy_requirement === -1) ? 'bg-gray-200' : 'bg-white')}
            onBlur={(e) => {
              if(e.target.value === '') {
                if(ref_PROkg.current) {
                  let PROkg = (energy_requirement * (percentPRO/100)) / 4;
                  PROkg /= weight;
                  PROkg = (Math.round(PROkg * 10)) / 10;
  
                  ref_PROkg.current.value = PROkg;
                }
              }
              else {
                  if(val !== -1) {
                    let PROkg = parseFloat(e.target.value);
                    let percent = (((PROkg * weight) * 4) / energy_requirement) * 100;
                    percent = (Math.round(percent * 10)) / 10;
  
                    update_distribution('PROkg', percent);
                  }
              }
            }}
            onChange={(e) => setVal(e.target.value)} 
            onKeyUp={(e) => {
              if(e.key === 'Enter') {
                if(e.target.value === '') {
                  if(ref_PROkg.current) {
                    let PROkg = (energy_requirement * (percentPRO/100)) / 4;
                    PROkg = (Math.round(PROkg * 10)) / 10;
    
                    ref_PROkg.current.value = PROkg;
                  }
                }
                else {
                  if(val !== -1) {
                    let PROkg = parseFloat(e.target.value);
                    let percent = (((PROkg * weight) * 4) / energy_requirement) * 100;
                    percent = (Math.round(percent * 10)) / 10;
  
                    update_distribution('PROkg', percent);
                  }
                }
  
                if(ref_PROkg.current) {
                  ref_PROkg.current.blur();
                }
              }
            }}></input>
            <div className='w-fit h-10 border border-gray-300 rounded-r-md px-4 flex justify-center items-center'>g/kg/d</div>
          </div>
        </div>
      </>
    )
}
  
const Select_Pregnant = ({options, handle_onchange}) => {
    return (
        <>
        <p className='notoser-reg text-web-black my-4'>โปรดระบุระยะตั้งครรภ์ / ช่วงให้นมบุตร</p>
        <select onChange={(e) => handle_onchange(e.target.value)}
        className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 mb-4'>
            {
                options.map((x, i) => {
                return (
                    <option key={i} value={x}>{x}</option>
                )
                })
            }
        </select>
        </>
    )
}

const Form_Accordion_Assessment = (props) => {
    const [ isOpen, setIsOpen ] = useState(true);
    const [ accordion_height, setAccordionHeight ] = useState('0px');
    const contentRef = useRef(null);
    const ERRef = useRef(null);

    const [ valueHN, setValueHN ] = useState('');
    const [ valueAN, setValueAN ] = useState('');
    const [ patient_name, setPatient_name ] = useState('');

    const [ male, setMale ] = useState(false);
    const [ female, setFemale ] = useState(false);
    const [ age, setAge ] = useState(-1);
    const [ weight, setWeight ] = useState(-1);
    const [ height, setHeight ] = useState(-1);
    const [ simple_value, setSimple_value ] = useState(0);
    const [ activity_value, setActivity_value ] = useState(0);
    const [ energy_requirement_index, setEnergy_requirement_index ] = useState(0);
    const [ stress, setStress ] = useState(1);
    const [ pregnancy, setPregnancy ] = useState('');

    const [ energy_requirement, setEnergy_requirement ] = useState(-1);
    const [ percentCHO, setPercentCHO ] = useState(55);
    const [ percentPRO, setPercentPRO ] = useState(15);
    const [ percentFAT, setPercentFAT ] = useState(30);

    const ER_options = [
        "โปรดระบุสมการคำนวณพลังงาน...",
        "Simple equation",
        "Harris Benedict (1919)",
        "Schofield (1985)(คำนวณจากน้ำหนัก)",
        "Schofield (1985)(คำนวณจากน้ำหนักและส่วนสูง)",
        "Miffflin-St Jeor (1990)",
        "Owen (1986, 1987)",
    ];

    const pregnant_options = [
        "ไม่มีครรภ์ / ไม่ใช่ช่วงให้นมบุตร",
        "หญิงตั้งครรภ์ ไตรมาสที่ 1",
        "หญิงตั้งครรภ์ ไตรมาสที่ 2",
        "หญิงตั้งครรภ์ ไตรมาสที่ 3",
        "หญิงให้นมบุตร ช่วง 6 เดือนแรก",
        "หญิงให้นมบุตร หลังช่วง 6 เดือนแรก",
    ];

    useEffect(() => {
      if(props.HN) {
        setValueHN(props.HN);
      }
    }, [props.HN]);

    useEffect(() => {
      if(props.AN) {
        setValueAN(props.AN);
      }
    }, [props.AN]);

    useEffect(() => {
      if(props.patient_name) {
        setPatient_name(props.patient_name);
      }
    }, [props.patient_name]);

    useEffect(() => {
      if(contentRef.current && ERRef.current) {
        setAccordionHeight(isOpen ? `${contentRef.current.scrollHeight + ERRef.current.scrollHeight}px` : '0px');
      }
    }, [isOpen]);

    useEffect(() => {
        calculate_requirement();
    }, [male, age, weight, height, simple_value, activity_value]);

    useEffect(() => {
      const sodium_requirement_mg = calculate_sodium_requirement();
      props.handle_update(energy_requirement, percentCHO, percentPRO, percentFAT, sodium_requirement_mg);
    }, [energy_requirement, percentCHO, percentPRO, percentFAT]);

    function calculate_sodium_requirement() {
      let result = 2000;

      /*
      if(age !== -1 && (!male && !female)) {
        if(age > 0 && age <= 1) {
          result = 550;
        }
        else if(age > 1 &&  age < 4) {
          result = 675;
        }
        else if(age >= 4 &&  age < 6) {
          result = 900;
        }
      }
      */

      return result;
    }

    function calculate_requirement() {
        if(!male && !female) {
            return;
        }

        if(age === -1) {
            return;
        }

        if(weight === -1) {
            return;
        }

        if(height === -1) {
            return;
        }

        if(simple_value === 0 && activity_value === 0) {
            return;
        }

        let result = 0;

        //Calculate basal requirement
        if(ER_options[energy_requirement_index] === "โปรดระบุสมการคำนวณพลังงาน...") {
            return;
        }
        else if(ER_options[energy_requirement_index] === "Simple equation") {
            result = weight * simple_value;
        }
        else if(ER_options[energy_requirement_index] === "Harris Benedict (1919)") {
            result = get_harris_benedict();
        }
        else if(ER_options[energy_requirement_index] === "Schofield (1985)(คำนวณจากน้ำหนัก)") {
            result = get_schofield_w();
        }
        else if(ER_options[energy_requirement_index] === "Schofield (1985)(คำนวณจากน้ำหนักและส่วนสูง)") {
            result = get_schofield_w_h();
        }
        else if(ER_options[energy_requirement_index] === "Miffflin-St Jeor (1990)") {
            result = get_mifflin_jeor();
        }
        else if(ER_options[energy_requirement_index] === "Owen (1986, 1987)") {
            result = get_owen();
        }

        //Calculate stress
        result *= stress;

        //Calculate pregnancy
        if(pregnancy === "หญิงตั้งครรภ์ ไตรมาสที่ 1") {
            result += 75;
        }
        else if(pregnancy === "หญิงตั้งครรภ์ ไตรมาสที่ 2") {
            result += 275;
        }
        else if(pregnancy === "หญิงตั้งครรภ์ ไตรมาสที่ 3") {
            result += 475;
        }
        else if(pregnancy === "หญิงให้นมบุตร ช่วง 6 เดือนแรก") {
            result += 500;
        }
        else if(pregnancy === "หญิงให้นมบุตร หลังช่วง 6 เดือนแรก") {
            result += 300;
        }
    
        setEnergy_requirement(result);

        function get_mifflin_jeor()
        {
            const result_male   = (10 * weight) + (6.25 * height) - (5 * age) + 5;
            const result_female = (10 * weight) + (6.25 * height) - (5 * age) - 161;

            return (male) ? result_male : result_female;
        }

        function get_owen()
        {
            const result_male   = 879 + (10.2 * weight);
            const result_female = 795 + (7.18 * weight);

            return (male) ? result_male : result_female;
        }

        function get_schofield_w_h()
        {
            let result_male   = 0;
            let result_female = 0;

            if(age < 30)
            {
                result_male = (15 * weight) - (10 * (height / 100)) + 706;
                result_female = (13.6 * weight) + (283 * (height / 100)) + 98;
            }
            else if(age >= 30 && age <= 60)
            {
                result_male = (11.5 * weight) - (2.6 * (height / 100)) + 877;
                result_female = (8.1 * weight) + (1.4 * (height / 100)) + 844;
            }
            else
            {
                result_male = (9.1 * weight) + (972 * (height / 100)) - 834;
                result_female = (7.9 * weight) + (458 * (height / 100)) + 17.7;
            }

            return (male) ? result_male : result_female;
        }

        function get_schofield_w()
        {
            let result_male   = 0;
            let result_female = 0;

            if(age < 30)
            {
                result_male = (15 * weight) + 692.1;
                result_female = (14.8 * weight) + 486.6;
            }
            else if(age >= 30 && age <= 60)
            {
                result_male = (11.5 * weight) + 873;
                result_female = (8.1 * weight) + 845.6;
            }
            else
            {
                result_male = (11.7 * weight) + 587.7;
                result_female = (9.1 * weight) + 658.4;
            }

            return (male) ? result_male : result_female;
        }

        function get_harris_benedict()
        {
            const result_male   = 66.4730 + (13.7516 * weight) + (5.0033 * height) - (6.7550 * age);
            const result_female = 655.0955 + (9.5634 * weight) + (1.8496 * height) - (4.6756 * age);
            return (male) ? result_male : result_female;
        }
    }

    return (
        <div className='my-4'>
            <button type='button' className={"w-full px-8 py-4 text-left border-b border-gray-300 flex justify-between items-center bg-white " + (isOpen ? 'rounded-t-md' : 'rounded-md')} onClick={() => setIsOpen(!isOpen)}>
                <h3 className='notoser-bold text-2xl text-orange-primary text-center sm:text-start'>ข้อมูลผู้ป่วย</h3>
            </button>

            <div ref={contentRef} className="overflow-hidden rounded-b-md transition-all duration-300 ease-in-out" style={{ maxHeight: accordion_height }}>
                <div className='pt-8 pb-16 px-8 bg-white'>
                    <div className='w-full h-fit flex justify-center mt-4 mb-2'>
                        <img src={logo} className='w-24 h-auto' />
                    </div>

                    <p className='notoser-med text-center'><i>แบบฟอร์มคำนวนพลังงานและสารอาหาร</i></p>
                    <p className='notoser-med text-center'><i>หน่วยโภชนาการ โรงพยาบาลพญาไท 2</i></p>

                    <h3 className='notoser-bold text-2xl text-orange-primary mt-16 text-center sm:text-start'>ข้อมูลผู้ป่วย</h3>
                    <p className='notoser-reg text-web-black my-4'>โปรดระบุ Hospital Number และ Admission Number</p>

                    <div className='grid grid-cols-2 gap-2'>
                        <Input_Hospital_Number id={'HN'} placeholder={'Hospital Number'} value={valueHN}
                        handle_onchange={(new_value) => {
                          setValueHN(new_value);
                        }}/>

                        <Input_Hospital_Number id={'AN'} placeholder={'Admission Number'} value={valueAN}
                        handle_onchange={(new_value) => {
                          setValueAN(new_value);
                        }}/>
                    </div>

                    <p className='notoser-reg text-web-black my-4'>โปรดระบุชื่อ-นามสกุลของผู้ป่วย</p>
                    <input type='text' disabled={true} id='ชื่อนามสกุล' name='ชื่อนามสกุล' value={patient_name} placeholder='โปรดระบุชื่อ-นามสกุลของผู้ป่วย'
                    className={'border focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-1 cursor-not-allowed opacity-60 ' + ((patient_name.length > 0) ? 'bg-green-100 border-green-500' : 'bg-white border-gray-200')}
                    onChange={(e) => setPatient_name(e.target.value)}></input>

                    <Group_Button_Sex male={male} female={female} handle_onchange={(isMale) => {
                        setMale(isMale);
                        setFemale(!isMale);
                    }}/>

                    <p className='notoser-reg text-web-black my-4'>โปรดเลือกอายุ (ปี)</p>
                    <input type='number' placeholder='โปรดเลือกอายุ (ปี)' onChange={(e) => setAge((e.target.value === '') ? -1 : e.target.value)}
                    className='bg-white border border-gray-200 focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-1'></input>

                    <p className='notoser-reg text-web-black my-4'>โปรดเลือกน้ำหนัก (กิโลกรัม)</p>
                    <input type='number' placeholder='โปรดเลือกน้ำหนัก (กิโลกรัม)' onChange={(e) => setWeight((e.target.value === '') ? -1 : e.target.value)}
                    className='bg-white border border-gray-200 focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-1'></input>

                    <p className='notoser-reg text-web-black my-4'>โปรดเลือกส่วนสูง (เซนติเมตร)</p>
                    <input type='number' placeholder='โปรดเลือกส่วนสูง (เซนติเมตร)' onChange={(e) => setHeight((e.target.value === '') ? -1 : e.target.value)}
                    className='bg-white border border-gray-200 focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-1'></input>

                    <div className='w-full h-fit' ref={ERRef}>
                      <Select_ER_equations options={ER_options} 
                      handle_onchange={(selectedIndex, simple_value, activity_value) => {
                        setEnergy_requirement_index(selectedIndex);
                        setSimple_value(simple_value);
                        setActivity_value(activity_value);
                      }}
                      handle_height_change={(ER_options_height) => {
                        if(contentRef.current && ERRef.current) {
                          setAccordionHeight(isOpen ? `${contentRef.current.scrollHeight + ER_options_height}px` : '0px');
                        }
                      }}/>
                    </div>

                    <Select_Stress_factors male={male} handle_onchange={(value) => {
                        setStress(value);
                    }}/>

                    <Nutrients_distribution weight={weight} energy_requirement={energy_requirement}
                    handle_update={(CHO, PRO, FAT) => {
                        setPercentCHO(CHO);
                        setPercentPRO(PRO);
                        setPercentFAT(FAT);
                    }}/>

                    <Select_Pregnant options={pregnant_options} handle_onchange={(e) => setPregnancy(e.target.value)}/>
                </div>
            </div>
        </div>
    )
}

export default Form_Accordion_Assessment