import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import '../../../../pages/Staff_login/staff_login.css'
import logo from '../../../../assets/logo/logo.jpg';
import ModalConfirmForm from '../../Modal/ModalConfirmForm';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import ModalWaiting from '../../Modal/ModalWaiting/ModalWaiting';
import url_list from '../../../../url/url_list';

// Import Swiper styles
import 'swiper/css';
import Form_Accordion_CCPC from './Form_Accordion_CCPC';
import Form_Accordion_DM from './Form_Accordion_DM';
import Form_Accordion_Other from './Form_Accordion_Other';

const Input_Hospital_Number = ({id, placeholder, value, handle_onchange}) => {
    const [ focus, setFocus ] = useState(false);
    const [ correct, setCorrect ] = useState(false);
    const [ input_value, setInput_value ] = useState('');
    const [ note, setNote ] = useState('');

    const ref = useRef(null);

    useEffect(() => {
        if(value !== '') {
            setInput_value(value);
            setFocus(true);
        }
    }, [value]);

    useEffect(() => {
        validate_input();
    }, [input_value]);

    function validate_input() {
        setFocus(false);

        if(ref.current && ref.current.value.length > 0) {
            if(!ref.current.value.includes("/")) {
                setCorrect(false);
                setNote("ขาดเครื่องหมาย /");
                return;
            }
    
            const components = ref.current.value.split("/");
            if(!components.length === 2) {
                setCorrect(false);
                setNote("มีเครื่องหมาย / มากกว่า 1 ตำแหน่ง");
                return;
            }
    
            if(components[0].length !== 5) {
                setCorrect(false);
                setNote("จำนวนตัวเลขไม่ถูกต้อง");
                return;
            }
    
            if(components[1].length !== 2) {
                setCorrect(false);
                setNote("จำนวนตัวเลขไม่ถูกต้อง");
                return;
            }
        }

        setNote('');
        setCorrect(true);
    }

    return (
        <div className='w-full h-fit'>
            <input type='text' 
            placeholder={placeholder} 
            ref={ref} id={id} name={id}
            value={input_value}
            className={'border focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-1 focus:bg-white ' + ((input_value.length === 0) ? 'bg-white border-gray-200' : (!focus && correct) ? 'bg-green-100 border-green-500' : 'bg-red-100 border-red-500')}
            onChange={(e) => {
                //setInput_value(e.target.value);
                handle_onchange(e.target.value);
            }}
            onFocus={() => setFocus(true)} 
            onBlur={() => validate_input()}></input>

            <p className={'notoser-reg text-web-black text-xs text-red-500 ' + (note === '' ? 'hidden' : 'block')}><u>หมายเหตุ</u> :{note}</p>
        </div>
    )
}

const Form_OPD = () => {
    const [ loading_screen, setLoading_screen ] = useState(true);
    const [ res_main, setRes_main ] = useState({});
    const [ res_behav, setRes_behav ] = useState({});

    const [ modal_submit_process, setModal_submit_process ] = useState("กำลังส่งข้อมูล (0 %)");
    const [ is_SubmitConfirm, setIsSubmitConfirm ] = useState(false);
    const [ is_SubmitLoading, setIsSubmitLoading ] = useState(false);
    const [ is_SubmitSuccess, setIsSubmitSuccess ] = useState(null);
    const [ active_back, setActive_back ] = useState(false);
    const [ physicians, setPhysicians ] = useState([]);
    const [ dietitians, setDietitians ] = useState([]);

    const [ physician_is_other, setPhysician_is_other ] = useState(false);
    const [ validated_dietitian_name, setValidated_dietitian_name ] = useState(false);

    //value states are used to activated validate function of each component
    const [ valueHN, setValueHN ] = useState('');
    const [ valueAN, setValueAN ] = useState('');
    const [ patient_name, setPatient_name ] = useState('');
    const [ valueRecord_data, setValueRecord_data ] = useState({});

    const navigate = useNavigate();

    const { name } = useParams();
    const { id } = useParams();

    const ref_physicians = useRef(null);
    const ref_dietitian = useRef(null);
    const ref_CCPC = useRef(null);

    useEffect(() => {
        let abortcontroller = new AbortController();

        setLoading_screen(true);

        axios.get("https://script.google.com/macros/s/AKfycbyudAsCiiRA7iUlogdTwcDZlUxZocx-NcLuQBSfGwRCyd7TIm3de145s1G1Kc_kDGyg/exec", { signal: abortcontroller.signal })
        .then((response) => {
            console.log(response.data);

            if (response.data.length > 0) {
                setPhysicians(response.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });

        axios.get("https://script.google.com/macros/s/AKfycbznGcrqNx0oHYHJz7ths4xec7CG_D29zxeeCHN_Gf242aQN4mEgZ8-kMeqxnUnqfDOw3A/exec", { signal: abortcontroller.signal })
        .then((response) => {
            console.log(response.data);

            if (response.data.length > 0) {
                setDietitians(response.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });

        return () => abortcontroller.abort();
    }, []);

    useEffect(() => {
        if(name && dietitians) {
            const dietitian_index = dietitians.findIndex(x => x.name === name);

            if(dietitian_index !== -1) {
                //index 0 is for 'ไม่ระบุ'
                if(ref_dietitian.current) {
                    ref_dietitian.current.selectedIndex = dietitian_index + 1;
                }

                setValidated_dietitian_name(true);
            }
        }

        close_loading_screen_on_initialization();
    }, [physicians, dietitians]);

    useEffect(() => {
        if(id) {
            let abortcontroller = new AbortController();

            const fetchData = async () => {
                try {
                    const requests = [
                        axios.get(url_list.url_load_and_update_OPD_record_id, { 
                            params: { id: id },
                            signal: abortcontroller.signal 
                        }),
                        /*
                        axios.get(url_list.url_load_behav_record_id, { 
                            params: { id: id },
                            signal: abortcontroller.signal 
                        }),
                        */
                    ];

                    const index_main = 0;
                    const index_behav = 1;
                    const responses = await Promise.all(requests);

                    setRes_main(responses[0].data[index_main]);
                    setRes_behav(responses[0].data[index_behav]);
                }
                catch (error) {
                    if (axios.isCancel(error)) {
                        console.log('Request canceled:', error.message);
                    } else {
                        alert('โหลดข้อมูลไม่สำเร็จ โปรดลองกดรีเฟรช หรือเข้าใหม่ในภายหลัง หรือติดต่อผู้พัฒนา');
                        console.error('Error fetching data:', error);
                    }
                }
            };

            fetchData();

            return () => abortcontroller.abort();
        }
    }, [id]);

    useEffect(() => {
        if(Object.keys(res_main).length > 0) {
            setValueHN(res_main['HN']);
            setValueAN(res_main['AN']);
            document.getElementById('ชื่อนามสกุล').value = res_main['ชื่อนามสกุล'];

            if(ref_physicians.current) {
                const physician_options = Array.from(ref_physicians.current.options).map(x => x.value);
                const physician_index = physician_options.indexOf(res_main['แพทย์']);
                const other_index = physician_options.indexOf('อื่นๆ');
                ref_physicians.current.selectedIndex = (physician_index === -1) ? other_index : physician_index;
                setPhysician_is_other(physician_index === -1);

                if(physician_index === -1 && physician_options.length > 0) {
                    document.getElementById('แพทย์').value = res_main['แพทย์'];
                }
            }

            //set CCPC
            document.getElementById('check_ACS').checked = (parseInt(res_main['ACS']) === 1);
            document.getElementById('check_HF').checked = (parseInt(res_main['HF']) === 1);
            document.getElementById('check_Stroke').checked = (parseInt(res_main['Stroke']) === 1);
            document.getElementById('check_TKA').checked = (parseInt(res_main['TKA']) === 1);
            document.getElementById('check_TBI').checked = (parseInt(res_main['TBI']) === 1);
            document.getElementById('check_CCPC_other').checked = (parseInt(res_main['CCPC_other']) === 1);
            document.getElementById('CCPC_other_specify').value = res_main['CCPC_other_specify'];

            document.getElementById('admit').value = res_main['admit'].split('T')[0];
            document.getElementById('dayIPD').value = res_main['dayIPD'].split('T')[0];
            document.getElementById('visit_day2').value = res_main['visit_day2'].split('T')[0];
            document.getElementById('visit_day5').value = res_main['visit_day5'].split('T')[0];
            document.getElementById('discharge').value = res_main['discharge'].split('T')[0];

            //set Behavior
            for(let i = 0; i <= 2; i++) {
                document.getElementById('Date_AS' + i).value = res_main['Date_AS' + i].split('T')[0];
                const food_type_options = Array.from(document.getElementById('ลักษณะอาหาร_AS' + i).options).map(x => x.value);
                const food_type_index = food_type_options.indexOf(res_main['ลักษณะอาหาร_AS' + i]);
                document.getElementById('ลักษณะอาหาร_AS' + i).selectedIndex = food_type_index;
            }

            setValueRecord_data(res_main);
            fulfill_select_with_record_data('ผ่านเกณฑ์_THT', res_main['ผ่านเกณฑ์_THT'].toString());
            fulfill_select_with_record_data('F/U', res_main['F/U'].toString());

            //in case follow up
            if(res_main['F/U'] === 1) {
                document.getElementById('วัน_F/U').value = res_main['วัน_F/U'].split('T')[0];
            }
            
            //set SOC
            document.getElementById('SOC_IPD_Date').value = res_main['SOC_IPD_Date'].split('T')[0];
            document.getElementById('SOC_OPD1_Date').value = res_main['SOC_OPD1_Date'].split('T')[0];
            document.getElementById('SOC_OPD2_Date').value = res_main['SOC_OPD2_Date'].split('T')[0];

            //set LDL
            document.getElementById('LDL_IPD_Date').value = res_main['LDL_IPD_Date'].split('T')[0];
            document.getElementById('LDL_OPD1_Date').value = res_main['LDL_OPD1_Date'].split('T')[0];
            document.getElementById('LDL_OPD2_Date').value = res_main['LDL_OPD2_Date'].split('T')[0];

            document.getElementById('CCPC_goal').value = res_main['CCPC_goal'];
            
            //set DM
            document.getElementById('check_new_DM').checked = (parseInt(res_main['new_DM']) === 1);
            document.getElementById('check_poor_DM').checked = (parseInt(res_main['poor_DM']) === 1);
            document.getElementById('check_GDM').checked = (parseInt(res_main['GDM']) === 1);
            document.getElementById('check_DLP').checked = (parseInt(res_main['DLP']) === 1);
            document.getElementById('check_WL').checked = (parseInt(res_main['ลดน้ำหนัก']) === 1);
            document.getElementById('check_Osteoporosis').checked = (parseInt(res_main['osteoporosis']) === 1);
            document.getElementById('check_DM_other').checked = (parseInt(res_main['DM_other']) === 1);
            document.getElementById('DM_other_specify').value = res_main['DM_other_specify'];

            //set HbA1C
            for(let i = 1; i <= 3; i++) {
                document.getElementById('HbA1C_Date' + i).value = res_main['HbA1C_Date' + i].split('T')[0];
                document.getElementById('HbA1C' + i).value = res_main['HbA1C' + i];
            }

            fulfill_select_with_record_data('ติดตาม_DM', res_main['ติดตาม_DM'].toString());

            //in case follow up
            if(res_main['ติดตาม_DM'] === 1) {
                document.getElementById('วันFU_DM').value = res_main['วันFU_DM'].split('T')[0];
            }

            document.getElementById('DM_goal').value = res_main['DM_goal'];

            //set Other
            document.getElementById('check_Med').checked = (parseInt(res_main['Med']) === 1);
            document.getElementById('check_Onco').checked = (parseInt(res_main['Onco']) === 1);
            document.getElementById('check_ไตเทียม').checked = (parseInt(res_main['ไตเทียม']) === 1);
            document.getElementById('check_เด็ก').checked = (parseInt(res_main['เด็ก']) === 1);
            document.getElementById('check_Neuro').checked = (parseInt(res_main['Neuro']) === 1);
            document.getElementById('check_Cardio').checked = (parseInt(res_main['Cardio']) === 1);
            document.getElementById('check_GI').checked = (parseInt(res_main['GI']) === 1);
            document.getElementById('check_Ortho').checked = (parseInt(res_main['Ortho']) === 1);
            document.getElementById('check_Other_other').checked = (parseInt(res_main['Other_other']) === 1);
            document.getElementById('check_tel').checked = (parseInt(res_main['ผู้ป่วยติดต่อทางโทรศัพท์']) === 1);
            document.getElementById('Other_other_specify').value = res_main['Other_other_specify'];

            fulfill_select_with_record_data('ติดตาม_other', res_main['ติดตาม_other'].toString());

            //in case follow up
            if(res_main['ติดตาม_other'] === 1) {
                document.getElementById('วันFU_other').value = res_main['วันFU_other'].split('T')[0];
            }

            document.getElementById('Other_goal').value = res_main['Other_goal'];

            set_process_icons();
            close_loading_screen_on_initialization();
        }
    }, [res_main]);

    useEffect(() => {
        if(Object.keys(res_behav).length > 0) {
            if(ref_CCPC.current) {
                ref_CCPC.current.update_behav_from_record(res_behav);
            }

            close_loading_screen_on_initialization();
        }
    }, [res_behav]);

    function close_loading_screen_on_initialization() {
        //call this function to finish first loading process
        const finish_loading_physicians = (physicians.length > 0);
        const finish_loading_dietitians = (dietitians.length > 0);
        const finish_loading_main = (Object.keys(res_main).length > 0);
        const finish_loading_behav = (Object.keys(res_behav).length > 0);

        if(id) {
            if(finish_loading_physicians && 
            finish_loading_dietitians &&
            finish_loading_main &&
            finish_loading_behav) {
                setLoading_screen(false);
            }
        }
        else {
            if(finish_loading_physicians && finish_loading_dietitians) {
                setLoading_screen(false);
            }
        }
    }

    function fulfill_select_with_record_data(selectID, value) {
        const element = document.getElementById(selectID);
        const options = Array.from(element.options).map(x => x.value);
        const index = options.indexOf(value);
        element.selectedIndex = index;
    }

    function submit_form() {
        setIsSubmitConfirm(true);
    }

    const handle_cancel = () => {
        setIsSubmitConfirm(false);
        setIsSubmitLoading(false);
        setIsSubmitSuccess(null);
    }
    
    const handle_confirm = async () => {
        setIsSubmitLoading(true);

        await PrepareData();

        if(id) {
            //update data
            let sending_main_form_success = await UpdateMainForm();

            if(!sending_main_form_success) {
                alert("หยุดส่งข้อมูลเนื่องจากพบข้อผิดพลาด");
                setIsSubmitLoading(false);
                setIsSubmitSuccess('failed');
                return;
            }
            
            await Handle_submit_success();
        }
        else {
            //insert new data
            let sending_main_form_success = await SendMainForm();

            if(!sending_main_form_success) {
                alert("หยุดส่งข้อมูลเนื่องจากพบข้อผิดพลาด");
                setIsSubmitLoading(false);
                setIsSubmitSuccess('failed');
                return;
            }

            await Handle_submit_success();
        }

        function Handle_submit_success() {
            return new Promise((resolve) => {
            setIsSubmitLoading(false);
            setIsSubmitSuccess('success');
            setActive_back(true);
            resolve();
            });
        }

        function PrepareData() {
            return new Promise((resolve) => {
                const result_yes = 1;
                const result_no = 0;

                document.getElementById('แพทย์').disabled = false;
                document.getElementById('แพทย์').value = (document.getElementById('select_physician').value === 'อื่นๆ') ? document.getElementById('แพทย์').value : document.getElementById('select_physician').value;
                
                document.getElementById('ACS').value = (document.getElementById('check_ACS').checked) ? result_yes : result_no;
                document.getElementById('HF').value = (document.getElementById('check_HF').checked) ? result_yes : result_no;
                document.getElementById('Stroke').value = (document.getElementById('check_Stroke').checked) ? result_yes : result_no;
                document.getElementById('TKA').value = (document.getElementById('check_TKA').checked) ? result_yes : result_no;
                document.getElementById('TBI').value = (document.getElementById('check_TBI').checked) ? result_yes : result_no;
                document.getElementById('CCPC_other').value = (document.getElementById('check_CCPC_other').checked) ? result_yes : result_no;

                document.getElementById('new_DM').value = (document.getElementById('check_new_DM').checked) ? result_yes : result_no;
                document.getElementById('poor_DM').value = (document.getElementById('check_poor_DM').checked) ? result_yes : result_no;
                document.getElementById('GDM').value = (document.getElementById('check_GDM').checked) ? result_yes : result_no;
                document.getElementById('DLP').value = (document.getElementById('check_DLP').checked) ? result_yes : result_no;
                document.getElementById('ลดน้ำหนัก').value = (document.getElementById('check_WL').checked) ? result_yes : result_no;
                document.getElementById('osteoporosis').value = (document.getElementById('check_Osteoporosis').checked) ? result_yes : result_no;
                document.getElementById('DM_other').value = (document.getElementById('check_DM_other').checked) ? result_yes : result_no;

                document.getElementById('Med').value = (document.getElementById('check_Med').checked) ? result_yes : result_no;
                document.getElementById('Onco').value = (document.getElementById('check_Onco').checked) ? result_yes : result_no;
                document.getElementById('ไตเทียม').value = (document.getElementById('check_ไตเทียม').checked) ? result_yes : result_no;
                document.getElementById('เด็ก').value = (document.getElementById('check_เด็ก').checked) ? result_yes : result_no;
                document.getElementById('Neuro').value = (document.getElementById('check_Neuro').checked) ? result_yes : result_no;
                document.getElementById('Cardio').value = (document.getElementById('check_Cardio').checked) ? result_yes : result_no;
                document.getElementById('GI').value = (document.getElementById('check_GI').checked) ? result_yes : result_no;
                document.getElementById('Ortho').value = (document.getElementById('check_Ortho').checked) ? result_yes : result_no;
                document.getElementById('Other_other').value = (document.getElementById('check_Other_other').checked) ? result_yes : result_no;
                document.getElementById('ผู้ป่วยติดต่อทางโทรศัพท์').value = (document.getElementById('check_tel').checked) ? result_yes : result_no;

                document.getElementById('ผ่านเกณฑ์ความเข้าใจ_AS').disabled = false;
                document.getElementById('ผ่านเกณฑ์พฤติกรรม_AS').disabled = false;
                document.getElementById('SOC_ผ่าน').disabled = false;
                document.getElementById('LDL_ผ่าน').disabled = false;
                document.getElementById('CCPC_diagnosis').disabled = false;
                document.getElementById('DM_diagnosis').disabled = false;
                document.getElementById('Other_diagnosis').disabled = false;

                document.getElementById('CCPC_diagnosis').value = (document.getElementById('CCPC_diagnosis').value > 0) ? document.getElementById('CCPC_diagnosis').value : document.getElementById('ccpc_select_diagnosis').value;
                document.getElementById('DM_diagnosis').value = (document.getElementById('DM_diagnosis').value > 0) ? document.getElementById('DM_diagnosis').value : document.getElementById('dm_select_diagnosis').value;
                document.getElementById('Other_diagnosis').value = (document.getElementById('Other_diagnosis').value > 0) ? document.getElementById('Other_diagnosis').value : document.getElementById('other_select_diagnosis').value;
            
                document.getElementById('ผู้ประเมิน').value = ref_dietitian.current.value;
                
                resolve();
            })
        }

        function SendMainForm() {
            return new Promise((resolve) => {
                const scriptURL = 'https://script.google.com/macros/s/AKfycbyWKPkynWVSnqSV3zUbe9wEGptpkTE8k6yHzdkgsIivTDv17M-QcVEXGRCmrxXtKOuq3A/exec';
                const form = document.forms['google-sheet'];

                setModal_submit_process("กำลังส่งข้อมูล (30 %)");

                fetch(scriptURL, { method: 'POST', body: new FormData(form)})
                .then(res => res.json())
                .then(res => {
                    console.log(res);
                    resolve(true);
                })
                .catch(err => {
                    console.log(err);
                    alert("การส่งข้อมูลไม่สำเร็จ");
                    resolve(false);
                });
            });
        }

        function UpdateMainForm() {
            return new Promise((resolve) => {
                const scriptURL = url_list.url_load_and_update_OPD_record_id;
                const form = document.forms['google-sheet'];
                //form.append('id', id); // Attach the id to the form data

                setModal_submit_process("กำลังส่งข้อมูล (30 %)");

                fetch(scriptURL, { 
                    method: 'POST', 
                    body: new FormData(form),
                    params: { id: id },
                })
                .then(res => res.json())
                .then(res => {
                    console.log(res);
                    resolve(true);
                })
                .catch(err => {
                    console.log(err);
                    alert("การส่งข้อมูลไม่สำเร็จ");
                    resolve(false);
                });
            });
        }
    }

    const [ process_info, setProcess_info ] = useState([
        {
            icon: 'ข้อมูลส่วนตัว',
            type: 'solid',
            name: 'user-circle',
            text_icon: null,
            process: null,
            active: false,
            elementID: 'HN',
            accordionTitleID: null,
        },
        {
            icon: 'แพทย์ที่ตรวจประเมิน',
            type: null,
            name: 'plus-medical',
            text_icon: null,
            process: null,
            active: false,
            elementID: 'select_physician',
            accordionTitleID: null,
        },
    ]);

    const [ process_ccpc, setProcess_ccpc ] = useState([
        {
            icon: 'ภาวะทางการแพทย์',
            type: 'solid',
            name: 'thermometer',
            text_icon: null,
            process: null,
            active: false,
            elementID: 'check_ACS',
            accordionTitleID: 'accordion_ccpc_header',
        },
        {
            icon: 'กระบวนการโภชนบำบัด',
            type: null,
            name: 'calendar',
            text_icon: null,
            process: '0/5',
            active: false,
            elementID: 'admit',
            accordionTitleID: 'accordion_ccpc_header',
        },
        {
            icon: 'คะแนนพฤติกรรม',
            type: 'solid',
            name: 'trophy',
            text_icon: null,
            process: '0/3',
            active: false,
            elementID: 'Date_AS0',
            accordionTitleID: 'accordion_ccpc_header',
        },
        {
            icon: 'ติดตามต่อ(ccpc)',
            type: 'solid',
            name: 'edit-alt',
            text_icon: null,
            process: null,
            active: false,
            elementID: 'F/U',
            accordionTitleID: 'accordion_ccpc_header',
        },
        {
            icon: 'Stage of Change',
            type: null,
            name: 'happy',
            text_icon: null,
            process: '0/3',
            active: false,
            elementID: 'SOC_IPD_Date',
            accordionTitleID: 'accordion_ccpc_header',
        },
        {
            icon: 'LDL',
            type: null,
            name: null,
            text_icon: 'LDL',
            process: '0/3',
            active: false,
            elementID: 'LDL_IPD_Date',
            accordionTitleID: 'accordion_ccpc_header',
        },
        {
            icon: 'Nutritional Diagnosis',
            type: null,
            name: 'notepad',
            text_icon: null,
            process: null,
            active: false,
            elementID: 'ccpc_select_diagnosis',
            accordionTitleID: 'accordion_ccpc_header',
        },
    ]);

    const [ process_dm, setProcess_dm ] = useState([
        {
            icon: 'ภาวะทางการแพทย์',
            type: 'solid',
            name: 'thermometer',
            text_icon: null,
            process: null,
            active: false,
            elementID: 'check_new_DM',
            accordionTitleID: 'accordion_dm_header',
        },
        {
            icon: 'A1C',
            type: null,
            name: null,
            text_icon: 'A1C',
            process: '0/3',
            active: false,
            elementID: 'HbA1C1',
            accordionTitleID: 'accordion_dm_header',
        },
        {
            icon: 'Nutritional Diagnosis',
            type: null,
            name: 'notepad',
            text_icon: null,
            process: null,
            active: false,
            elementID: 'DM_goal',
            accordionTitleID: 'accordion_dm_header',
        },
        {
            icon: 'ติดตามต่อ(DM)',
            type: 'solid',
            name: 'edit-alt',
            text_icon: null,
            process: null,
            active: false,
            elementID: 'ติดตาม',
            accordionTitleID: 'accordion_dm_header',
        },
    ]);

    const [ process_other, setProcess_other ] = useState([
        {
            icon: 'ภาวะทางการแพทย์',
            type: 'solid',
            name: 'thermometer',
            text_icon: null,
            process: null,
            active: false,
            elementID: 'check_Med',
            accordionTitleID: 'accordion_other_header',
        },
        {
            icon: 'Nutritional Diagnosis',
            type: null,
            name: 'notepad',
            text_icon: null,
            process: null,
            active: false,
            elementID: 'other_select_diagnosis',
            accordionTitleID: 'accordion_other_header',
        },
    ]);

    const [ process_dietary_intake, setProcess_dietary_intake ] = useState([
        {
            icon: 'การบริโภคอาหาร',
            type: 'solid',
            name: 'bowl-rice',
            text_icon: null,
            process: null,
            active: false,
            elementID: null,
            accordionTitleID: null,
        },
    ]);

    const Icon_process = ({description, type, name, text_icon, process, active, elementID, accordionTitleID}) => {
        const [ hover, setHover ] = useState(false);

        const color_primary_orange = '#fe9d6a';
        const color_inactive_gray = '#A9A9A9';
        const color_white = '#FFFFFF';

        function handle_icon_clicked() {
            open_accordion().then(scroll_to_element);

            function open_accordion() {
                return new Promise((resolve) => {
                    if(accordionTitleID !== null) {
                        let accordion_title = document.getElementById(accordionTitleID);
                        if(!accordion_title.classList.contains('rounded-t-md')) { accordion_title.click(); }
                    }
                    resolve();
                })
            }

            function scroll_to_element() {
                return new Promise((resolve) => {
                    setTimeout(() => {
                        if(elementID !== null) {
                            document.getElementById(elementID).scrollIntoView({ behavior: "smooth", block: "center" });
                        }
                    }, 200);
                    resolve();
                })
            }
        }

        return (
            <div className='relative' onClick={() => handle_icon_clicked()}>
                <div className='relative cursor-pointer'
                onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                    <div className={'w-8 h-8 flex justify-center items-center rounded-full'} style={{backgroundColor: hover ? (active ? color_primary_orange : color_inactive_gray) : ''}}>
                        {
                            (text_icon) ? 
                                <p className='notoser-exbold text-xs text-web-black text-center' style={{color: hover ? '#FFFFFF' : (active ? color_primary_orange : color_inactive_gray)}}>{text_icon}</p>
                            :
                                <box-icon type={type ? type : null} name={name} color={hover ? color_white : (active ? color_primary_orange : color_inactive_gray)}></box-icon>
                        }
                    </div>

                    {
                        process ? <p className='notoser-semi text-xs text-center' style={{color: active ? color_primary_orange : color_inactive_gray}}>{process}</p> : null
                    }
                </div>

                <div className={"absolute top-0 -left-1 mb-2 w-max px-2 py-1 bg-gray-700 text-white text-sm rounded-md -translate-x-full " + (hover ? 'block' : 'hidden')}>
                    {description}
                </div>
            </div>
        );
    }

    const Icon_process_small = ({type, name, text_icon, process, active, elementID, accordionTitleID}) => {
        const [ hover, setHover ] = useState(false);

        const color_primary_orange = '#fe9d6a';
        const color_inactive_gray = '#A9A9A9';
        const color_white = '#FFFFFF';

        function handle_icon_clicked() {
            open_accordion().then(scroll_to_element);

            function open_accordion() {
                return new Promise((resolve) => {
                    if(accordionTitleID !== null) {
                        let accordion_title = document.getElementById(accordionTitleID);
                        if(!accordion_title.classList.contains('rounded-t-md')) { accordion_title.click(); }
                    }
                    resolve();
                })
            }

            function scroll_to_element() {
                return new Promise((resolve) => {
                    setTimeout(() => {
                        if(elementID !== null) {
                            document.getElementById(elementID).scrollIntoView({ behavior: "smooth", block: "center" });
                        }
                    }, 200);
                    resolve();
                })
            }
        }

        return (
            <div className='flex gap-1 cursor-pointer' onClick={() => handle_icon_clicked()} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <div className='w-6 h-6 flex justify-center items-center rounded-full' style={{backgroundColor: hover ? (active ? color_primary_orange : color_inactive_gray) : ''}}>
                    {
                        (text_icon) ? 
                            <p className='notoser-exbold text-web-black text-center' style={{color: hover ? '#FFFFFF' : (active ? color_primary_orange : color_inactive_gray), fontSize: '8px'}}>{text_icon}</p>
                        :
                            <div className='w-fit h-6'>
                                <box-icon type={type ? type : null} name={name} color={hover ? color_white : (active ? color_primary_orange : color_inactive_gray)} size='xs'></box-icon>
                            </div>
                    }
                </div>

                {
                    process ? 
                        <div className='w-fit h-6 flex items-center'>
                            <p className='notoser-reg text-xs text-center' style={{color: active ? color_primary_orange : color_inactive_gray, fontSize: '8px'}}>{process}</p>
                        </div>
                    : 
                        null
                }
            </div>
        );
    }

    function set_process_icons() {
        setProcess_Info();
        setProcess_CCPC();
        setProcess_DM();
        setProcess_Other();

        function setProcess_Other() {
            //Other Medical conditions
            const check_Med = document.getElementById('check_Med');
            const check_Onco = document.getElementById('check_Onco');
            const check_Kidney = document.getElementById('check_ไตเทียม');
            const check_Children = document.getElementById('check_เด็ก');
            const check_Neuro = document.getElementById('check_Neuro');
            const check_Cardio = document.getElementById('check_Cardio');
            const check_GI = document.getElementById('check_GI');
            const check_Ortho = document.getElementById('check_Ortho');
            const Other_other_specify = document.getElementById('Other_other_specify');

            let new_result_other_medical_condition = get_new_result(process_other, 'ภาวะทางการแพทย์', 
                (check_Med.checked || check_Onco.checked || check_Kidney.checked || check_Children.checked || check_Neuro.checked || check_Cardio.checked || check_GI.checked || check_Ortho.checked || Other_other_specify.value !== ''));
            setProcess_other(new_result_other_medical_condition);

            //Other Goal
            let result_other_goal = get_new_result_from_element_with_other_case('other_select_diagnosis', 'Other_diagnosis');
            let new_result_other_goal = get_new_result(process_other, 'Nutritional Diagnosis', result_other_goal && document.getElementById('Other_goal').value !== '');
            setProcess_other(new_result_other_goal);
        }

        function setProcess_DM() {
            //DM Medical conditions
            const check_new_DM = document.getElementById('check_new_DM');
            const check_poor_DM = document.getElementById('check_poor_DM');
            const check_GDM = document.getElementById('check_GDM');
            const check_DLP = document.getElementById('check_DLP');
            const check_WL = document.getElementById('check_WL');
            const DM_other_specify = document.getElementById('DM_other_specify');

            let new_result_dm_medical_condition = get_new_result(process_dm, 'ภาวะทางการแพทย์', 
                (check_new_DM.checked || check_poor_DM.checked || check_GDM.checked || check_DLP.checked || check_WL.checked || DM_other_specify.value !== ''));
            setProcess_dm(new_result_dm_medical_condition);

            //DM A1C
            let a1c_count = 0;
            for(let i = 1; i <= 3; i++) {
                a1c_count += (document.getElementById('HbA1C_Date' + i).value !== '' && document.getElementById('HbA1C' + i).value !== '') ? 1 : 0;
            }

            let new_result_dm_a1c = get_new_result(process_dm, 'A1C', a1c_count > 0, a1c_count + "/3");
            setProcess_dm(new_result_dm_a1c);

            //DM Goal
            let result_dm_goal = get_new_result_from_element_with_other_case('dm_select_diagnosis', 'DM_diagnosis');
            let new_result_dm_goal = get_new_result(process_dm, 'Nutritional Diagnosis', result_dm_goal && document.getElementById('DM_goal').value !== '');
            setProcess_dm(new_result_dm_goal);

            //DM Continue follow up
            const dm_FU = document.getElementById('ติดตาม_DM');
            const dm_FU_date = document.getElementById('วันFU_DM');
            
            if(dm_FU.value === 'ไม่ระบุ') {
                let new_result_dm_continue = get_new_result(process_dm, 'ติดตามต่อ(DM)', false);
                setProcess_dm(new_result_dm_continue);
            }
            else {
                if(dm_FU.value === '1') {
                    let new_result_dm_continue = get_new_result(process_dm, 'ติดตามต่อ(DM)', dm_FU_date.value !== '');
                    setProcess_dm(new_result_dm_continue);
                }
                else {
                    let new_result_dm_continue = get_new_result(process_dm, 'ติดตามต่อ(DM)', true);
                    setProcess_dm(new_result_dm_continue);
                }
            }
        }

        function setProcess_CCPC() {
            //CCPC Medical conditions
            const check_ACS = document.getElementById('check_ACS');
            const check_HF = document.getElementById('check_HF');
            const check_Stroke = document.getElementById('check_Stroke');
            const check_TKA = document.getElementById('check_TKA');
            const check_TBI = document.getElementById('check_TBI');
            const CCPC_other_specify = document.getElementById('CCPC_other_specify');

            let new_result_ccpc_medical_condition = get_new_result(process_ccpc, 'ภาวะทางการแพทย์', 
                (check_ACS.checked || check_HF.checked || check_Stroke.checked || check_TKA.checked || check_TBI.checked || CCPC_other_specify.value !== ''));
            setProcess_ccpc(new_result_ccpc_medical_condition);

            //CCPC Nutrition care process
            let days_count = 0;
            days_count += (document.getElementById('admit').value === '') ? 0 : 1;
            days_count += (document.getElementById('dayIPD').value === '') ? 0 : 1;
            days_count += (document.getElementById('discharge').value === '') ? 0 : 1;

            for(let i = 0; i <= 5; i++) {
                if(i === 2 || i === 5) {
                    days_count += (document.getElementById('visit_day' + i).value !== '') ? 1 : 0;
                }
            }

            let new_result_ccpc_nutrition_process = get_new_result(process_ccpc, 'กระบวนการโภชนบำบัด', days_count > 0, days_count + "/4");
            setProcess_ccpc(new_result_ccpc_nutrition_process);

            //CCPC Behavior score
            let behave_count = 0;
            for(let i = 0; i <= 2; i++) {
                behave_count += (document.getElementById('Date_AS' + i).value !== '' 
                && document.getElementById('คะแนนความเข้าใจ' + i + '_หลัง').value !== 'ไม่ระบุ'
                && document.getElementById('คะแนนพฤติกรรม' + i + '_หลัง').value !== '') ? 1 : 0;
            }

            let new_result_ccpc_behave = get_new_result(process_ccpc, 'คะแนนพฤติกรรม', behave_count > 0, behave_count + "/3");
            setProcess_ccpc(new_result_ccpc_behave);

            //CCPC Continue follow up
            const ccpc_FU = document.getElementById('F/U');
            const ccpc_FU_date = document.getElementById('วัน_F/U');

            if(ccpc_FU.value === 'ไม่ระบุ') {
                let new_result_ccpc_behave_continue = get_new_result(process_ccpc, 'ติดตามต่อ(ccpc)', false);
                setProcess_ccpc(new_result_ccpc_behave_continue);
            }
            else {
                if(ccpc_FU.value === '1') {
                    let new_result_ccpc_behave_continue = get_new_result(process_ccpc, 'ติดตามต่อ(ccpc)', ccpc_FU_date.value !== '');
                    setProcess_ccpc(new_result_ccpc_behave_continue);
                }
                else {
                    let new_result_ccpc_behave_continue = get_new_result(process_ccpc, 'ติดตามต่อ(ccpc)', true);
                    setProcess_ccpc(new_result_ccpc_behave_continue);
                }
            }

            //CCPC Stage of change
            let SOC_count = 0;
            for(let i = 0; i <= 2; i++) {
                if(i === 0) {
                    SOC_count += (document.getElementById('SOC_IPD_Date').value !== '' && document.getElementById('SOC_IPD').value !== 'ไม่ระบุ') ? 1 : 0;
                }
                else {
                    SOC_count += (document.getElementById('SOC_OPD' + i + '_Date').value !== '' && document.getElementById('SOC_OPD' + i).value !== 'ไม่ระบุ') ? 1 : 0;
                }
            }

            let new_result_ccpc_SOC = get_new_result(process_ccpc, 'Stage of Change', SOC_count > 0, SOC_count + "/3");
            setProcess_ccpc(new_result_ccpc_SOC);

            //CCPC LDL
            let ldl_count = 0;
            for(let i = 0; i <= 2; i++) {
                if(i === 0) {
                    ldl_count += (document.getElementById('LDL_IPD_Date').value !== '' && document.getElementById('LDL_IPD').value !== '') ? 1 : 0;
                }
                else {
                    ldl_count += (document.getElementById('LDL_OPD' + i + '_Date').value !== '' && document.getElementById('LDL_OPD' + i).value !== '') ? 1 : 0;
                }
            }

            let new_result_ccpc_ldl = get_new_result(process_ccpc, 'LDL', ldl_count > 0, ldl_count + "/3");
            setProcess_ccpc(new_result_ccpc_ldl);

            //CCPC Goal
            let result_ccpc_goal = get_new_result_from_element_with_other_case('ccpc_select_diagnosis', 'CCPC_diagnosis');
            let new_result_ccpc_goal = get_new_result(process_ccpc, 'Nutritional Diagnosis', result_ccpc_goal && document.getElementById('CCPC_goal').value !== '');
            setProcess_ccpc(new_result_ccpc_goal);
        }

        function setProcess_Info() {
            const element_HN = document.getElementById('HN');
            const element_AN = document.getElementById('AN');
            const element_patient = document.getElementById('ชื่อนามสกุล');

            const validated_text = 'bg-green-100';

            //Info
            if(element_HN.value === '' || element_AN.value === '' || element_patient.value === '') {
                let new_result_info = get_new_result(process_info, 'ข้อมูลส่วนตัว', false);
                setProcess_info(new_result_info);
            }
            else {
                let new_result_info = get_new_result(process_info, 'ข้อมูลส่วนตัว', (element_HN.classList.contains(validated_text) && element_AN.classList.contains(validated_text) && element_patient.value !== ''));
                setProcess_info(new_result_info);
            }

            //Physician
            let result_physician = get_new_result_from_element_with_other_case('select_physician', 'แพทย์');
            let new_result_physician = get_new_result(process_info, 'แพทย์ที่ตรวจประเมิน', result_physician);
            setProcess_info(new_result_physician);
        }

        function get_new_result_from_element_with_other_case(getElementById, element_otherID) {
            const element = document.getElementById(getElementById);
            const element_other = document.getElementById(element_otherID);

            let result;

            if(element.value === 'ไม่ระบุ') {
                result = false;
            }
            else if(element.value === 'อื่นๆ') {
                result = element_other.value !== '';
            }
            else {
                result = true;
            }

            return result;
        }

        function get_new_result(old, icon_name, active, process) {
            let new_result = [...old];
            new_result[old.findIndex(x => x.icon === icon_name)].active = active;

            if(process) {
                new_result[old.findIndex(x => x.icon === icon_name)].process = process;
            }

            return new_result;
        }
    }

    function redirect_to_NutirtionIntake_Form() {
        /*
        const form_main = document.forms['google-sheet'];
        const form_main_data = new FormData(form_main);

        // Convert to an object
        const form_main_obj = {};

        form_main_data.forEach((value, key) => {
            // If the key already exists, handle as an array (e.g., checkboxes)
            if (form_main_obj[key]) {
                form_main_obj[key] = [].concat(form_main_obj[key], value);
            } else {
                form_main_obj[key] = value;
            }
        });
        */

        navigate('/staff/form/nutritionintake/' + name, { 
            state: { 
                HN: valueHN,
                AN: valueAN,
                patient_name: patient_name,
            } 
        });
    }

    return (
        <>
            <div className='relative staff_login_container'>
                <div className='absolute left-0 top-0 w-screen h-screen z-10 block md:flex justify-end gap-5'>
                    <div className='block md:hidden w-full h-fit flex justify-start px-2 my-2 gap-2 overflow-x-scroll'>
                        <div className={'w-fit h-fit flex gap-1 rounded-full bg-white flex-row px-2 py-1 items-start'}>
                            {
                                process_info.map((x, i) => {
                                    return (
                                        <Icon_process_small key={i} type={x.type} name={x.name} text_icon={x.text_icon} process={x.process} active={x.active} elementID={x.elementID} accordionTitleID={x.accordionTitleID}/>
                                    );
                                })
                            }
                        </div>

                        <div className={'w-fit h-fit flex gap-1 rounded-full bg-white flex-row px-2 py-1 items-start'}>
                            {
                                process_ccpc.map((x, i) => {
                                    return (
                                        <Icon_process_small key={i} type={x.type} name={x.name} text_icon={x.text_icon} process={x.process} active={x.active} elementID={x.elementID} accordionTitleID={x.accordionTitleID}/>
                                    );
                                })
                            }
                        </div>

                        <div className={'w-fit h-fit flex gap-1 rounded-full bg-white flex-row px-2 py-1 items-start'}>
                            {
                                process_dm.map((x, i) => {
                                    return (
                                        <Icon_process_small key={i} type={x.type} name={x.name} text_icon={x.text_icon} process={x.process} active={x.active} elementID={x.elementID} accordionTitleID={x.accordionTitleID}/>
                                    );
                                })
                            }
                        </div>

                        <div className={'w-fit h-fit flex gap-1 rounded-full bg-white flex-row px-2 py-1 items-start'}>
                            {
                                process_other.map((x, i) => {
                                    return (
                                        <Icon_process_small key={i} type={x.type} name={x.name} text_icon={x.text_icon} process={x.process} active={x.active} elementID={x.elementID} accordionTitleID={x.accordionTitleID}/>
                                    );
                                })
                            }
                        </div>

                        <div className={'w-fit h-fit flex gap-1 rounded-full bg-white flex-row px-2 py-1 items-start'}>
                            {
                                process_dietary_intake.map((x, i) => {
                                    return (
                                        <div onClick={() => redirect_to_NutirtionIntake_Form()}>
                                            <Icon_process_small key={i} type={x.type} name={x.name} text_icon={x.text_icon} process={x.process} active={x.active} elementID={x.elementID} accordionTitleID={x.accordionTitleID}/>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>

                    <div className='grow h-screen overflow-y-auto ml-6 mr-6 md:mr-0'>
                        <div className='w-full h-fit mt-2 mb-10'>
                            <form id="google-sheet" name="google-sheet" className='w-full h-fit'>
                                <div className='w-full h-fit px-8 md:px-12 py-14 bg-white rounded-md'>
                                    <div className='w-full h-fit flex justify-center mt-4 mb-2'>
                                        <img src={logo} className='w-24 h-auto' />
                                    </div>

                                    <p className='notoser-med text-center'><i>แบบฟอร์มบันทึกสถิติ OPD</i></p>
                                    <p className='notoser-med text-center'><i>หน่วยโภชนาการ โรงพยาบาลพญาไท 2</i></p>

                                    <h3 className='notoser-bold text-2xl text-orange-primary mt-16 text-center sm:text-start'>ข้อมูลผู้ป่วย</h3>
                                    <p className='notoser-reg text-web-black my-4'>โปรดระบุ Hospital Number และ Admission Number</p>
                                    <div className='grid grid-cols-2 gap-2'>
                                        <Input_Hospital_Number id={'HN'} placeholder={'Hospital Number'} value={valueHN} 
                                        handle_onchange={(new_value) => {
                                            setValueHN(new_value);
                                            set_process_icons();
                                        }}/>
                                        
                                        <Input_Hospital_Number id={'AN'} placeholder={'Admission Number'} value={valueAN} 
                                        handle_onchange={(new_value) => {
                                            setValueAN(new_value);
                                            set_process_icons();
                                        }}/>
                                    </div>

                                    <p className='notoser-reg text-web-black my-4'>โปรดระบุชื่อ-นามสกุลของผู้ป่วย</p>
                                    <input type='text' id='ชื่อนามสกุล' name='ชื่อนามสกุล' value={patient_name} placeholder='โปรดระบุชื่อ-นามสกุลของผู้ป่วย'
                                    className='bg-white border border-gray-200 focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-1'
                                    onChange={(e) => {
                                        setPatient_name(e.target.value);
                                        set_process_icons();
                                    }}></input>

                                    <p className='notoser-reg text-web-black mt-4 mb-4'>แพทย์ที่ตรวจประเมิน</p>
                                    <select ref={ref_physicians} id="select_physician" name="select_physician"
                                    className={'notoser-reg border border-gray-200 focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-2'}
                                    onChange={(e) => {
                                        document.getElementById('แพทย์').value = '';
                                        setPhysician_is_other(e.target.value === 'อื่นๆ');
                                        set_process_icons();
                                    }}>
                                        <option value="ไม่ระบุ">ไม่ระบุ</option>

                                        {
                                            physicians.map((x, i) => {
                                                return (
                                                    <option key={i} value={x.name}>{x.name}</option>
                                                );
                                            })
                                        }

                                        <option value="อื่นๆ">อื่นๆ</option>
                                    </select>

                                    <p className='notoser-reg text-web-black my-4'>แพทย์ที่ตรวจประเมิน (กรณีที่ไม่มีชื่อแพทย์ในตัวเลือก)</p>
                                    <input type='text' id='แพทย์' name='แพทย์' placeholder='โปรดระบุแพทย์ที่ตรวจประเมิน (กรณีที่ไม่มีชื่อแพทย์ในตัวเลือก)' disabled={!physician_is_other} onChange={() => set_process_icons()}
                                    className={'border border-gray-200 focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-1 ' + (physician_is_other ? 'bg-white' : 'bg-gray-300 cursor-not-allowed')}></input>
                                </div>

                                <Form_Accordion_CCPC record_data={valueRecord_data} behav_score_fromDB={res_behav} handle_onchange={() => set_process_icons()}/>
                                <Form_Accordion_DM record_data={valueRecord_data} handle_onchange={() => set_process_icons()}/>
                                <Form_Accordion_Other record_data={valueRecord_data} handle_onchange={() => set_process_icons()}/>
                                
                                <input type='text' className='hidden' id='ACS' name='ACS'/>
                                <input type='text' className='hidden' id='HF' name='HF'/>
                                <input type='text' className='hidden' id='Stroke' name='Stroke'/>
                                <input type='text' className='hidden' id='TKA' name='TKA'/>
                                <input type='text' className='hidden' id='TBI' name='TBI'/>
                                <input type='text' className='hidden' id='CCPC_other' name='CCPC_other'/>
                                <input type='text' className='hidden' id='CCPC_diagnosis' name='CCPC_diagnosis'></input>

                                <input type='text' className='hidden' id='new_DM' name='new_DM'/>
                                <input type='text' className='hidden' id='poor_DM' name='poor_DM'/>
                                <input type='text' className='hidden' id='GDM' name='GDM'/>
                                <input type='text' className='hidden' id='DLP' name='DLP'/>
                                <input type='text' className='hidden' id='ลดน้ำหนัก' name='ลดน้ำหนัก'/>
                                <input type='text' className='hidden' id='osteoporosis' name='osteoporosis'/>
                                <input type='text' className='hidden' id='DM_other' name='DM_other'/>
                                <input type='text' className='hidden' id='DM_diagnosis' name='DM_diagnosis'></input>

                                <input type='text' className='hidden' id='Med' name='Med'/>
                                <input type='text' className='hidden' id='Onco' name='Onco'/>
                                <input type='text' className='hidden' id='ไตเทียม' name='ไตเทียม'/>
                                <input type='text' className='hidden' id='เด็ก' name='เด็ก'/>
                                <input type='text' className='hidden' id='Neuro' name='Neuro'/>
                                <input type='text' className='hidden' id='Cardio' name='Cardio'/>
                                <input type='text' className='hidden' id='GI' name='GI'/>
                                <input type='text' className='hidden' id='Ortho' name='Ortho'/>
                                <input type='text' className='hidden' id='Other_other' name='Other_other'/>
                                <input type='text' className='hidden' id='ผู้ป่วยติดต่อทางโทรศัพท์' name='ผู้ป่วยติดต่อทางโทรศัพท์'/>
                                <input type='text' className='hidden' id='Other_diagnosis' name='Other_diagnosis'></input>

                                <input type='text' className='hidden' id='ผู้ประเมิน' name='ผู้ประเมิน'/>

                                { (id && id.length > 0) ? <input type='text' className='hidden' id='record_id' name='record_id' defaultValue={id}/> : null }
                            </form>

                            <div className='w-full h-fit px-8 md:px-12 py-14 bg-white rounded-md'>
                                <p className='notoser-reg text-web-black mt-4 mb-4'>นักกำหนดอาหารผู้กรอกข้อมูล</p>
                                <select ref={ref_dietitian} onChange={(e) => { setValidated_dietitian_name(e.target.value === name); }}
                                className={'notoser-reg border focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-2 ' + (validated_dietitian_name ? 'bg-green-100 border-green-500' : 'bg-white border-gray-200')}>
                                    <option value="ไม่ระบุ">ไม่ระบุ</option>
                                    
                                    {
                                        dietitians.map((x, i) => {
                                            return (
                                                <option key={i} value={x.name}>{x.name}</option>
                                            );
                                        })
                                    }
                                </select>

                                <p className='notoser-reg my-8 text-center sm:text-center'>โปรดตรวจสอบข้อมูลให้ครบถ้วนอีกครั้ง หลังจากกรอกข้อมูลครบถ้วนแล้ว โปรดกดปุ่ม "ยืนยัน" เพื่อส่งผลการประเมิน</p>

                                <div className='w-full h-fit flex justify-center gap-4 my-4'>
                                    <button className={'notoser-semi w-60 h-fit bg-orange-primary text-white px-10 py-4 rounded-md hover:bg-orange-bold ' + (active_back ? 'block' : 'hidden')} onClick={() => navigate(-1)}>กลับสู่หน้าหลัก</button>
                                    <button className='notoser-semi w-60 h-fit bg-orange-primary text-white px-10 py-4 rounded-md hover:bg-orange-bold' onClick={() => submit_form()}>ส่งข้อมูล</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='hidden md:block w-fit min-w-fit h-screen z-10 mr-6 mt-2 overflow-visible'>
                        <div className={'w-fit h-fit flex gap-1 rounded-full bg-white flex-col px-2 py-2 my-2'}>
                            {
                                process_info.map((x, i) => {
                                    return (
                                        <Icon_process key={i} description={x.icon} type={x.type} name={x.name} text_icon={x.text_icon} process={x.process} active={x.active} elementID={x.elementID} accordionTitleID={x.accordionTitleID}/>
                                    );
                                })
                            }
                        </div>

                        <div className={'w-fit h-fit flex gap-1 rounded-full bg-white flex-col px-2 py-2 my-2'}>
                            {
                                process_ccpc.map((x, i) => {
                                    return (
                                        <Icon_process key={i} description={x.icon} type={x.type} name={x.name} text_icon={x.text_icon} process={x.process} active={x.active} elementID={x.elementID} accordionTitleID={x.accordionTitleID}/>
                                    );
                                })
                            }
                        </div>

                        <div className={'w-fit h-fit flex gap-1 rounded-full bg-white flex-col px-2 py-2 my-2'}>
                            {
                                process_dm.map((x, i) => {
                                    return (
                                        <Icon_process key={i} description={x.icon} type={x.type} name={x.name} text_icon={x.text_icon} process={x.process} active={x.active} elementID={x.elementID} accordionTitleID={x.accordionTitleID}/>
                                    );
                                })
                            }
                        </div>

                        <div className={'w-fit h-fit flex gap-1 rounded-full bg-white flex-col px-2 py-2 my-2'}>
                            {
                                process_other.map((x, i) => {
                                    return (
                                        <Icon_process key={i} description={x.icon} type={x.type} name={x.name} text_icon={x.text_icon} process={x.process} active={x.active} elementID={x.elementID} accordionTitleID={x.accordionTitleID}/>
                                    );
                                })
                            }
                        </div>

                        <div className={'w-fit h-fit flex gap-1 rounded-full bg-white flex-col px-2 py-2 my-2'}>
                            {
                                process_dietary_intake.map((x, i) => {
                                    return (
                                        <div onClick={() => redirect_to_NutirtionIntake_Form()}>
                                            <Icon_process key={i} description={x.icon} type={x.type} name={x.name} text_icon={x.text_icon} process={x.process} active={x.active} elementID={x.elementID} accordionTitleID={x.accordionTitleID}/>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className='absolute left-0 top-0 w-full h-full bg-black opacity-80 z-0'></div>
            </div>

            {/* Any Modal */}
            <ModalWaiting isShow={loading_screen}/>
            <ModalConfirmForm isShow={is_SubmitConfirm} isLoading={is_SubmitLoading} onCancel={handle_cancel} onConfirm={handle_confirm} isSuccess={is_SubmitSuccess} process={modal_submit_process}/>
        </>
    )
}

export default Form_OPD